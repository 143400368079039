<template>
  <div class="good_wrap">
    <div class="banner_head">商品</div>
    <div class="make_subheading">
      <div class="subheading_title">是否显示分类</div>
      <div class="subheading_check d-flex flex-wrap">
        <el-radio-group v-model="goods.param.is_cat" @change="isCatchange">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </div>
    </div>
    <template v-if="goods.param.is_cat == 1">
      <div class="make_subheading">
        <div class="subheading_title">分类模板</div>
        <div class="subheading_check d-flex flex-wrap">
          <el-radio-group v-model="goods.param.cat_position">
            <el-radio :label="0">顶部菜单</el-radio>
            <el-radio :label="1">左侧菜单</el-radio>
          </el-radio-group>
        </div>
      </div>
      <template v-if="goods.param.cat_position == 1"></template>
      <template v-else>
        <div class="make_subheading">
          <div class="subheading_title">菜单样式</div>
          <div class="subheading_check d-flex flex-wrap">
            <el-radio-group v-model="goods.param.cat_style">
              <el-radio :label="0">样式一</el-radio>
              <el-radio :label="1">样式二</el-radio>
            </el-radio-group>
          </div>
          <div class="goods_toast menu_style_toast">
            注：当只有一个分类时，顶部分类不显示
          </div>
        </div>
      </template>
      <div class="make_subheading">
        <div class="goods_catList">
          <div
            v-for="(value, key) in goods.param.list"
            :key="key"
            class="cat_single d-flex"
          >
            <div class="cat_individual">
              <div class="cat_input d-flex align-items-center">
                <div class="cat_input_title">商品分类</div>
                <div class="cat_input_content">
                  <div class="cat_input_text">{{ value.name }}</div>
                </div>
              </div>
              <div class="cat_input d-flex align-items-center">
                <div class="cat_input_title">副标题</div>
                <div class="cat_input_content">
                  <el-input v-model="value.second_name"></el-input>
                </div>
              </div>
              <div class="cat_input d-flex cat_good_num">
                <div class="cat_input_title">商品显示</div>
                <div
                  class="cat_input_content d-flex align-items-center flex-wrap"
                >
                  <el-radio-group v-model="value.goods_style" @input="goodsStyle($event,key)">
                    <el-radio :label="0">全部</el-radio>
                    <div class="goodCat_num">
                        <el-radio :label="1">
                          <div class="cat_num">
                            <el-input
                              type="number"
                              :min="1"
                              max="30"
                              :maxlength="2"
                              v-model="value.goods_num"
                              @input="goodsNum"
                            ></el-input>
                          </div>
                        </el-radio>
                    </div>
                    
                    <el-radio :label="2">自定义商品</el-radio>
                  </el-radio-group>
                  <div class="cat_goods" v-if="value.goods_style == 2">
                    <div
                      class="cat_goods_single"
                      v-for="(item, index) in value.goods_list"
                      :key="index"
                    >
                      <img :src="item.cover_pic" alt="" />
                      <div
                        class="close_img"
                        @click.stop="cat_good_del(key, index)"
                      >
                        <i class="el-icon-close"></i>
                      </div>
                    </div>
                    <div
                      class="cat_goods_add d-flex justify-content-center align-items-center"
                      v-if="value.goods_list.length < 30"
                      @click.stop="goodsSlect(key,value.ids)"
                    >
                      +
                    </div>
                  </div>
                  <div class="goods_toast">最多显示30个商品</div>
                </div>
              </div>
            </div>
            <div class="cat_handle">
              <div class="handle" @click.stop="cat_del(key)">
                <i class="el-icon-close"></i>
              </div>
              <div
                class="handle"
                v-if="key < goods.param.list.length - 1"
                @click.stop="cat_down(index)"
              >
                <i class="el-icon-arrow-down"></i>
              </div>
              <div class="handle" v-if="key > 0" @click.stop="cat_up(index)">
                <i class="el-icon-arrow-up"></i>
              </div>
            </div>
          </div>
          <div
            class="cat_add d-flex justify-content-center"
            v-if="goods.param.list.length < 8" @click.stop="addpendCart"
          >
            +添加分类
          </div>
          <div class="goods_toast">注：最多添加8个分类</div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="make_subheading">
        <div class="subheading_title">添加商品</div>
        <div class="goods_addtion d-flex flex-wrap">
          <template
            v-if="goods.param.list[0] && goods.param.list[0].goods_list.length"
          >
            <div
              v-for="(item, index) in goods.param.list[0].goods_list"
              :key="index"
              class="goods_img"
            >
              <img :src="item.cover_pic" alt="" />
              <div class="close_img" @click.stop="closeGood(index)">
                <i class="el-icon-close"></i>
              </div>
            </div>
          </template>

          <div
            class="goods_append d-flex justify-content-center align-items-center"
            @click.stop="goodsSlect(0,[])"
          >
            +
          </div>
        </div>
      </div>
    </template>
    <template
      v-if="goods.param.cat_position == 1 && goods.param.is_cat == 1"
    ></template>
    <template v-else>
      <div class="make_subheading">
        <div class="subheading_title">列表样式</div>
        <div class="listView">
          <template v-for="(text, index) in defaultList.goods.list_style">
            <div class="view_Good" v-if="goods.param.list_style == text.param">
              <img :src="text.image" alt="" />
              <div class="view_num" @click.stop="styleVisible = !styleVisible">
                {{ text.name }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="make_subheading">
        <div class="subheading_title">填充方式</div>
        <div class="subheading_check d-flex flex-wrap">
          <el-radio-group v-model="goods.param.fill">
            <el-radio :label="1">填充</el-radio>
            <el-radio :label="0">留白</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="make_subheading" v-if="goods.param.list_style == 0">
        <div class="subheading_title">显示比例</div>
        <div class="subheading_check d-flex flex-wrap">
          <el-radio-group v-model="goods.param.per">
            <el-radio :label="0">1:1</el-radio>
            <el-radio :label="2">2:1</el-radio>
            <el-radio :label="1">3:2</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="make_subheading">
        <div class="subheading_title">显示样式</div>
        <div class="subheading_check d-flex flex-wrap">
          <el-radio-group v-model="goods.param.style">
            <el-radio :label="0">常规</el-radio>
            <el-radio :label="1">边框</el-radio>
            <el-radio :label="2">居中显示</el-radio>
            <el-radio :label="3">边框居中</el-radio>
          </el-radio-group>
        </div>
      </div>
    </template>

    <div class="make_subheading">
      <div class="subheading_title">显示内容</div>
      <div class="subheading_check d-flex flex-wrap">
        <el-checkbox v-model="goods.param.name" :true-label="1" :false-label="0"
          >商品名称</el-checkbox
        >
        <el-checkbox
          v-model="goods.param.price"
          :true-label="1"
          :false-label="0"
          >商品价格</el-checkbox
        >
        <el-checkbox
          v-if="
            (goods.param.style < 2 || goods.param.list_style == 1) &&
            goods.param.list_style < 3
          "
          v-model="goods.param.buy"
          :true-label="1"
          :false-label="0"
          @change="buyCheck"
          >购买按钮</el-checkbox
        >
      </div>
      <div class="shop_cart d-flex flex-wrap" v-if="goods.param.buy == 1">
        <el-radio-group v-model="goods.param.buy_list" @change="buyChange">
          <el-radio :label="0">购物车</el-radio>
          <el-radio :label="1">加号</el-radio>
          <el-radio :label="2">文字样式1</el-radio>
          <el-radio :label="3">文字样式2</el-radio>
          <el-radio :label="4">文字样式3</el-radio>
          <el-radio :label="5">文字样式5</el-radio>
        </el-radio-group>
        <template v-if="goods.param.buy_list >= 2">
          <el-input
            v-model="goods.param.buy_content"
            placeholder="推荐输入2~5个字"
            maxlength="5"
            minlength="2"
          ></el-input>
        </template>
      </div>
      <div class="subheading_check d-flex fle-wrap">
        <el-checkbox
          v-model="goods.param.mark"
          :true-label="1"
          :false-label="0"
          @change="cornerCheck"
        >
          商品角标</el-checkbox
        >
      </div>
      <template v-if="goods.param.mark == 1">
        <div class="shop_cart d-flex flex-wrap">
          <el-radio-group
            v-model="goods.param.mark_list"
            @change="cornerChange"
          >
            <el-radio :label="0">热销</el-radio>
            <el-radio :label="1">新品</el-radio>
            <el-radio :label="2">折扣</el-radio>
            <el-radio :label="3">推荐</el-radio>
            <el-radio :label="4">自定义</el-radio>
          </el-radio-group>
          <div class="shop_cart_img" v-if="goods.param.mark_list == 4">
            <div class="upload-group">
              <template v-if="goods.param.mark_url">
                <img :src="goods.param.mark_url"  @click.stop="addPhoto('mark_url')" alt="" />
              </template>
              <template v-else>
                <p @click.stop="addPhoto('mark_url')">+添加图片</p>
                <p>建议尺寸64*64</p>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="nav_head d-flex align-items-center">
      <div class="d-flex align-items-center banner-style_title">上下间距</div>
      <div class="nav_background">
        <el-input v-model="goods.param.interval" type="number" :min="1">
          <template slot="append">px</template>
        </el-input>
      </div>
    </div>
    <el-dialog width="50%" title="风格选择器" :visible.sync="styleVisible">
      <div class="style_wrap">
        <div
          v-for="(item, index) in defaultList.goods.list_style"
          :key="index"
          :class="['manner_style', styleIndex == index ? 'active' : '']"
          @click.stop="styleClick(index)"
        >
          <div class="listImg">
            <img :src="item.image" alt="" />
          </div>
          <p>{{ item.name }}</p>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click.stop="styleClose">取消</el-button>
        <el-button @click="styleSubmit()" type="primary">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      goods: this.params,
      defaultList: {
        goods: {
          list_style: [
            {
              name: "大图",
              param: "0",
              image: require("../../../../assets/mobile/goodsOne.png"),
            },
            {
              name: "一行一个",
              param: "1",
              image: require("../../../../assets/mobile/goodsList.png"),
            },

            {
              name: "一行两个",
              param: "2",
              image: require("../../../../assets/mobile/twoGoodsList2.png"),
            },
            {
              name: "一行三个",
              param: "3",
              image: require("../../../../assets/mobile/otherGoodsthree3.png"),
            },

            {
              name: "左右滑动",
              param: "4",
              image: require("../../../../assets/mobile/otherGoodsthree.png"),
            },
          ],
        },
      },
      styleVisible: false,
      styleIndex: -1,
      allUrl: location.origin + location.pathname,
      catIndex: 0, //分类的下标
    };
  },
  props: {
    params: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch:{
    params:{
      handler(newVal){
        // //console.log(newVal,'shopgood组件里的')
      },
      deep:true,
      
    }
  },
  created() {
    if (this.params) {
      this.styleIndex = this.defaultList.goods.list_style.findIndex(
        (item) => item.param == this.goods.param.list_style
      );
    }
  },
  methods: {
    // 分类商品的删除
    cat_good_del(key, index) {
      // console.log(key,index,'key')
      this.goods.param.list[key].goods_list.splice(index, 1);
      this.$set(this.goods.param.list[key],'goods_list',this.goods.param.list[key].goods_list)
      this.$forceUpdate()
    },
    // 分类删除
    cat_del(index) {
      this.goods.param.list.splice(index, 1);
    },
    // 分类下降
    cat_down(index) {
      var options = JSON.parse(
        JSON.stringify(this.goods.param.list[index + 1])
      );
      this.$set(this.goods.param.list, index + 1, this.goods.param.list[index]);
      this.$set(this.goods.param.list, index, options);
    },
    // 分类上升
    cat_up(index) {
      var options = JSON.parse(
        JSON.stringify(this.goods.param.list[index - 1])
      );
      this.$set(this.goods.param.list, index - 1, this.goods.param.list[index]);
      this.$set(this.goods.param.list, index, options);
    },
    styleSubmit() {
      this.goods.param.list_style =
        this.defaultList.goods.list_style[this.styleIndex].param;
      this.styleVisible = !this.styleVisible;
    },
    styleClose() {
      this.styleVisible = !this.styleVisible;
    },
    styleClick(index) {
      this.styleIndex = index;
    },
    // 角标
    cornerChange(e) {
      switch (e) {
        case 0:
          this.goods.param.mark_url = this.allUrl + "/static/rx.png";
          break;
        case 1:
          this.goods.param.mark_url = this.allUrl + "/static/xp.png";
          break;
        case 2:
          this.goods.param.mark_url = this.allUrl + "/static/zk.png";
          break;
        case 3:
          this.goods.param.mark_url = this.allUrl + "/static/tj.png";
          break;
        default:
          this.goods.param.mark_url = "";
      }
    },
    // 购买按钮
    buyChange(e) {
      if (e == 0) {
        this.goods.param.buy_content = this.allUrl + "static/cat.png";
      } else if (e == 1) {
        this.goods.param.buy_content = this.allUrl + "static/add.png";
      } else {
        this.goods.param.buy_content = "";
      }
    },
    buyCheck(e) {
      if (e == 1) {
        this.buyChange(0);
      }
    },
    cornerCheck(e) {
      if (e == 1) {
        this.cornerChange(0);
      }
    },
    // 商品选择器
    goodsSlect(cat_index,cat_id) {
      this.catIndex = cat_index;
      let data = {
        type: "goods",
        catId:cat_id,
        value: this.goods,
      };
      this.$emit("change", data);
    },
    closeGood(index) {
      this.goods.param.list[0].goods_list.splice(index, 1);
    },
    goodsNum(e) {
      const maxLength = 2; // 设置最大长度为10
      if (e.length > maxLength) {
        e = e.slice(0, maxLength);
      }
    },
    // 添加分类
    addpendCart(){
      let data = {
        type: "class",
        value: this.goods,
      };
      this.$emit("change", data);
    },
    // 添加图片
    addPhoto(val){
      let data = {
        type: val,
        value: this.goods,
      };
      this.$emit("change", data);
    },
    goodsStyle(e,index){
      this.goods.param.list[index].goods_style=e;
      this.$set(this.goods.param.list[index],'goods_style',e)
      this.$forceUpdate()
    },
    isCatchange(e){
      this.goods.param.list=[];
    },
  },
};
</script>
<style lang="scss" scoped>
.good_wrap {
  width: 100%;
  background: #fff;
  .banner_head {
    padding: 7px;
    display: flex;
    padding-left: 15px;
    font-size: 16px;
    font-weight: 600;
    padding-top: 15px;
  }
  .make_subheading {
    border-bottom: 1px #eee solid;
    padding: 7px;
    .subheading_title {
      font-size: 14px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .subheading_check {
      padding: 10px 0;
      padding-left: 17px;
      /deep/.el-radio-group {
        .el-radio {
          margin-top: 5px;
        }
      }
    }
    .goods_addtion {
      // width: 100%;
      background-color: #fff;
      padding: 10px;
      border: 1px solid #e2e2e2;
      .goods_append {
        width: 50px;
        height: 50px;
        background-color: #f7f7f7;
        color: #5cb3fd;
        cursor: pointer;
        position: relative;
      }
      .goods_img {
        width: 50px;
        height: 50px;
        background-color: #f7f7f7;
        color: #5cb3fd;
        cursor: pointer;
        position: relative;
        margin: 0px 10px 10px 0px;
        img {
          width: 100%;
          height: 100%;
        }
        &:hover {
          .close_img {
            display: block;
          }
        }
        .close_img {
          position: absolute;
          top: -12px;
          right: -12px;
          width: 25px;
          height: 25px;
          display: none;
          z-index: 10;
          border-radius: 50%;
          text-align: center;
          line-height: 25px;
          background-color: #eee;
          i {
            font-size: 20px;
            line-height: 25px;
          }
        }
      }
    }
    .listView {
      width: 100%;
      .view_Good {
        width: 100%;
        font-size: 0;
        position: relative;
        background: #f6f7f9;
        border: 1px solid #eee;
        img {
          width: 100%;
          height: 100%;
        }
        .view_num {
          position: absolute;
          bottom: 0;
          left: 0;
          text-align: center;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          color: #fff;
          background: rgba(0, 0, 0, 0.3);
          padding: 0 10px;
          box-sizing: border-box;
          width: 100%;
          cursor: pointer;
        }
      }
    }
    .goods_toast {
      font-size: 12px;
      color: #d9534f;
      margin-top: 5px;
    }
    .menu_style_toast {
      margin-left: 17px;
    }
    .goods_catList {
      // width: 100%;
      padding: 0 15px;
      .cat_single {
        margin-bottom: 7px;
        position: relative;
        .cat_individual {
          width: 100%;
          background-color: #fff;
          padding: 10px;
          border: 1px solid #e2e2e2;
          .cat_input {
            margin-top: 21px;
            margin-bottom: 14px;
            .cat_input_title {
              min-width: 80px;
              text-align: right;
            }
            .cat_input_content {
              margin-left: 15px;
              flex: 1;
              /deep/.el-input {
                width: 100%;
              }
              .cat_input_text {
                font-size: 14px;
              }
              .cat_num {
                width: 80px;
              }
              .cat_goods {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .cat_goods_single {
                  margin: 10px 10px 10px 0px;
                  width: 50px;
                  height: 50px;
                  background-color: #f7f7f7;
                  color: #5cb3fd;
                  cursor: pointer;
                  position: relative;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                  &:hover {
                    .close_img {
                      display: block;
                    }
                  }
                  .close_img {
                    position: absolute;
                    top: -12px;
                    right: -12px;
                    width: 25px;
                    height: 25px;
                    display: none;
                    z-index: 10;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 25px;
                    background-color:#eee ;
                    i {
                      font-size: 20px;
                      line-height: 25px;
                    }
                  }
                }
                .cat_goods_add {
                  width: 50px;
                  height: 50px;
                  background-color: #f7f7f7;
                  color: #5cb3fd;
                  cursor: pointer;
                  position: relative;
                  margin-top: 10px;
                }
              }
            }
          }
        }
        .cat_handle {
          position: absolute;
          top: 0;
          right: 0rem;
          .handle {
            width: 28px;
            height: 28px;
            margin-bottom: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #5cb3fd;
            i {
              font-size: 24px;
              color: #fff;
            }
          }
        }
      }
      .cat_add {
        // width: 100%;
        padding: 5px;
        border: 1px dashed #5cb3fd;
        color: #5cb3fd;
        cursor: pointer;
      }
    }
  }
  .style_wrap {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-top: 20px;
    .manner_style {
      text-align: center;
      margin-bottom: 14px;
      margin-right: 14px;
      cursor: pointer;
      .listImg {
        width: 280px;
        height: 220px;
        border: 1px solid #ededed;
        border-radius: 2px;
        font-size: 0;
        background: #f7f8fa;
        img {
          width: 100%;
          border-radius: 2px;
          vertical-align: middle;
        }
      }
      p {
        font-size: 14px;
        color: #333;
        padding: 15px 5px;
        box-sizing: border-box;
        margin-bottom: 0;
      }
    }
    .manner_style.active {
      .listImg {
        border-color: #0275d8;
      }
    }
    .manner_style:nth-child(3n) {
      margin-right: 0;
    }
  }
  .shop_cart {
    margin-left: 10px;
    .shop_cart_img {
      width: 64px;
      height: 64px;
      border: 1px dashed rgb(238, 238, 238);
      font-size: 10px;
      padding: 5px 0px;
      text-align: center;
      .upload_group {
        text-align: center;
        cursor: pointer;
      }
      p:first-child {
        color: rgb(92, 179, 253);
        cursor: pointer;
        border: 0;
        display: block;
        margin: 0;
      }
      p {
        color: rgb(53, 53, 53);
        font-size: 12px;
      }
      img {
        width: 64px;
        height: 64px;
        text-align: center;
        background-color: #f7f7f7;
      }
    }
    /deep/.el-radio-group {
      .el-radio {
        margin-top: 5px;
      }
    }
    /deep/.el-input {
      margin-top: 10px;
    }
  }
  .nav_head {
    padding: 7px;
    .nav_background {
      display: flex;
      align-items: center;
      margin-left: 10px;
      /deep/.el-input {
        width: 100%;
      }
      /deep/.el-select {
        width: 100%;
      }
    }
  }
}
.goodCat_num{
  display: inline-block;
  margin-right: 30px;
  /deep/.el-radio{
    display: flex;
    align-items: center;
  }
}
.cat_good_num{
  .cat_input_title{
    margin-top: 17px;
  }
}
</style>