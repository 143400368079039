<template>
  <div class="sten_wrap">
    <div class="block" :style="{ background: stencil.backgroundColor }">
      <div class="block_head"></div>
      <div class="block_header">
        <div class="block_logo">
          <img :src="stencil.logo" alt="" />
        </div>
        <div class="block_capsule">
          <img src="../../../../assets/mobile/jiaonang.png" alt="" />
        </div>
      </div>
      <div class="block_search">
        <el-input readonly placeholder="请输入关键词">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
    </div>
    <div class="sten_content">
      <div
        v-for="(item, index) in tem_deta"
        :key="item.id"
        :class="['plugin', stencilIndex == index ? 'active' : '']"
        @click.stop="stencilClick(index)"
      >
        <template v-if="item.type == 'banner'">
          <div class="banner_wrap">
            <div :style="{ height: item.param.interval / 2 + 'px' }"></div>
            <div
              v-if="item.param.style == 1"
              class="swiper-container banner_swiper"
              :style="{ height: item.param.height / 2 + 'px' }"
            >
              <ul class="swiper-wrapper">
                <li
                  class="swiper-slide"
                  v-for="(i, b) in item.param.list"
                  :key="b"
                >
                  <img
                    :src="i.pic_url"
                    alt=""
                    :class="[item.param.fill == 1 ? 'fillImage' : '']"
                  />
                </li>
                <template v-if="item.param.list.length < 3">
                  <li
                    class="swiper-slide"
                    v-for="i in 3 - item.param.list.length"
                  >
                    <img
                      src="../../../../assets/diy/default.png"
                      :class="[item.param.fill == 1 ? 'fillImage' : '']"
                      alt=""
                    />
                  </li>
                </template>
              </ul>
            </div>
            <div
              v-else-if="item.param.style == 2"
              class="swiper-container isolate_banner"
              :style="{ height: item.param.height / 2 + 'px' }"
            >
              <ul class="swiper-wrapper">
                <li
                  class="swiper-slide"
                  v-for="(i, b) in item.param.list"
                  :key="b"
                >
                  <img
                    :src="i.pic_url"
                    alt=""
                    :class="[item.param.fill == 1 ? 'fillImage' : '']"
                  />
                </li>
                <template v-if="item.param.list.length < 3">
                  <li
                    class="swiper-slide"
                    v-for="i in 3 - item.param.list.length"
                  >
                    <img
                      src="../../../../assets/diy/default.png"
                      :class="[item.param.fill == 1 ? 'fillImage' : '']"
                      alt=""
                    />
                  </li>
                </template>
              </ul>
            </div>
            <div :style="{ height: item.param.interval / 2 + 'px' }"></div>
          </div>
        </template>
        <template v-if="item.type == 'nav'">
          <div class="nav_wrap">
            <div :style="{ height: item.param.interval / 2 + 'px' }"></div>
            <div class="nav-default d-flex">
              <div
                class="nav_container d-flex"
                :style="{
                  backgroundColor: item.param.backgroundColor,
                  overflowX: item.param.is_slide == 'true' ? 'auto' : 'hidden',
                  'min-height': item.param.list.length > 0 ? 'auto' : '100px',
                }"
              >
                <template v-for="(value, key) in item.param.default_list">
                  <div class="nav_sans d-flex flex-wrap">
                    <div
                      v-for="(i, b) in value"
                      :key="b"
                      :class="['nav_block', 'nav-item-' + item.param.count]"
                    >
                      <img v-if="i.pic_url" :src="i.pic_url" alt="" />
                      <div v-else class="nav_placeholder"></div>
                      <p class="show-one-lines">{{ i.name }}</p>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-if="item.type == 'marketing'">
          <div class="marke_wrap">
            <div class="marke_item" v-for="(i, b) in item.param.list" :key="b">
              <div class="marke_headline">
                <div class="marke_title">{{ i.title }}</div>
                <template v-if="i.label_show == 1">
                  <div
                    class="marke_label"
                    :class="'marke_label-' + i.label_style"
                    v-if="
                      i.label_content ||
                      (i.label_img_show == 1 && i.label_img_url)
                    "
                  >
                    <span>{{ i.label_content }}</span>
                    <img
                      :src="i.label_img_url"
                      class="marke_icon"
                      v-if="i.label_img_show == 1 && i.label_img_url"
                    />
                  </div>
                </template>
              </div>
              <div
                class="subtitle"
                :style="{ color: i.subtitle_color }"
                v-if="item.param.subtitle_show == 1 && i.subtitle"
              >
                {{ i.subtitle }}
              </div>
              <div class="marke_Img">
                <template v-for="(value, key) in i.pic_list">
                  <template v-if="value.pic_url">
                    <img :src="value.pic_url" alt="" />
                  </template>
                  <template v-else>
                    <img src="../../../../assets/diy/default.png" alt="" />
                  </template>
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-if="item.type == 'goods'">
          <div class="goods_wrap">
            <div :style="{ height: item.param.interval / 2 + 'px' }"></div>
            <div class="good_wrap">
              <template
                v-if="item.param.is_cat == 1 && item.param.cat_position == 1"
              >
                <div class="d-flex goods_default">
                  <div class="goods_cat">
                    <template
                      v-for="(value, key) in item.param.list.length > 0
                        ? item.param.list
                        : defaultList.goods.list"
                    >
                      <div class="goods-cat-one">
                        <div class="text-more">{{ value.name }}</div>
                      </div>
                    </template>
                  </div>
                  <div class="good_container">
                    <div
                      class="cat_posit_right"
                      v-for="(value, key) in item.param.list.length > 0
                        ? item.param.list
                        : defaultList.goods.list"
                    >
                      <div class="cat_posit_title">
                        <div class="text-more" style="width: 100px">
                          {{ value.name }}
                        </div>
                      </div>
                      <template>
                        <div
                          class="goods-cat-goods d-flex"
                          v-for="(v, k) in defaultList.goods.list[0].goods_list"
                          :key="k"
                        >
                          <div class="goods-img-1">
                            <div
                              class="goods-img fill-1"
                              :style="{
                                backgroundImage: 'url(' + v.pic_url + ')',
                              }"
                            >
                              <img
                                v-if="item.param.mark == 1"
                                :src="item.param.mark_url"
                              />
                            </div>
                          </div>
                          <div class="goods_left_wrap">
                            <div class="text-more" v-if="item.param.name == 1">
                              {{ v.name }}
                            </div>
                            <div
                              v-if="item.param.price == 1"
                              class="goods_left_price"
                            >
                              999.99积分
                            </div>
                            <div
                              class="d-flex justify-content-end"
                              v-if="item.param.buy == 1"
                            >
                              <div
                                style="max-width: 100px"
                                v-if="item.param.buy_list >= 2"
                              >
                                <div
                                  :class="
                                    'text-more buy-btn-' + item.param.buy_list
                                  "
                                  style="width: auto"
                                >
                                  {{
                                    item.param.buy_content
                                      ? item.param.buy_content
                                      : item.param.buy_default
                                  }}
                                </div>
                              </div>
                              <div v-else style="width: 25px">
                                <div
                                  class="goods-img fill-1"
                                  data-per="0"
                                  :style="{
                                    backgroundImage:
                                      'url(' + item.param.buy_content + ')',
                                  }"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <template
                  v-if="item.param.is_cat == 1 && item.param.list.length != 0"
                >
                  <div class="shop_cat d-flex align-items-center">
                    <template v-if="item.param.list.length > 0">
                      <template v-for="(value, key) in item.param.list">
                        <div class="mock_cat_one">
                          <div
                            v-if="item.param.cat_style == 1"
                            class="goods-font"
                          >
                            {{ value.name }}
                          </div>
                          <div
                            :class="
                              'mock_cat_one goods-cat-one goods-cat-one-' +
                              item.param.cat_style +
                              (key == 0 ? ' active' : '')
                            "
                          >
                            <div>{{ value.second_name || value.name }}</div>
                          </div>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div
                        v-for="(value, key) in defaultList.goods.list"
                        class="mock_cat_list"
                      >
                        <div class="mock_cat_one">
                          <div
                            v-if="item.param.cat_style == 1"
                            class="goods-font"
                          >
                            {{ value.cat_name }}一
                          </div>
                          <div
                            :class="[
                              'goods-cat-one active goods-cat-one-' +
                                item.param.cat_style,
                            ]"
                          >
                            <div>{{ value.name }}一</div>
                          </div>
                        </div>
                        <div class="mock_cat_one">
                          <div
                            v-if="item.param.cat_style == 1"
                            class="goods-font"
                          >
                            {{ value.cat_name }}二
                          </div>
                          <div class="mock_cat_one">{{ value.name }}二</div>
                        </div>
                        <div class="mock_cat_one">
                          <div
                            v-if="item.param.cat_style == 1"
                            class="goods-font"
                          >
                            {{ value.cat_name }}三
                          </div>
                          <div class="mock_cat_one">{{ value.name }}三</div>
                        </div>
                        <div class="mock_cat_one">
                          <div
                            v-if="item.param.cat_style == 1"
                            class="goods-font"
                          >
                            {{ value.cat_name }}四
                          </div>
                          <div class="mock_cat_one">{{ value.name }}四</div>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
                <div
                  :class="[
                    'd-flex',
                    'shop_wrap',
                    item.param.list_style == 4 ? '' : 'flex-wrap',
                  ]"
                >
                  <template
                    v-for="(value, key) in item.param.list.length > 0 &&
                    item.param.list[0].goods_list.length > 0
                      ? item.param.list[0].goods_list
                      : defaultList.goods.list[0].goods_list"
                  >
                    <div
                      :class="['shop_one', 'shop_one-' + item.param.list_style]"
                    >
                      <div
                        class="d-flex flex-wrap shop_good"
                        :class="['shop_good-border_' + item.param.style]"
                      >
                        <div
                          :class="['shop-goods-img' + item.param.list_style]"
                        >
                          <div
                            :class="[
                              'verticleGood_img',
                              'goods-img-' + item.param.list_style,
                              'fill-' + item.param.fill,
                              'per-' + item.param.per,
                            ]"
                            :style="{
                              backgroundImage:
                                'url(' + value.cover_pic || occupiedImg + ')',
                            }"
                          >
                            <img
                              v-if="item.param.mark == 1"
                              :src="item.param.mark_url"
                            />
                          </div>
                        </div>

                        <div
                          :class="[
                            'd-flex',
                            'flex-wrap',
                            'align-items-end',
                            'goods-content',
                            'goods-content-' + item.param.list_style,
                          ]"
                        >
                          <div
                            v-if="item.param.name == 1"
                            :class="[
                              'goods_name',
                              item.param.list_style == 0
                                ? 'text-more'
                                : 'text-more-2',
                            ]"
                          >
                            {{ value.name }}
                          </div>
                          <div
                            class="d-flex align-items-end goods-price"
                            v-if="item.param.price == 1"
                          >
                            <div class="flex-grow-1">
                              {{ value.price }}
                              <span class="intergral_unit">积分</span>
                            </div>
                          </div>
                          <template
                            v-if="
                              item.param.list_style != 2 || item.type == 'goods'
                            "
                          >
                            <div
                              :class="[
                                'buy-btn',
                                'd-flex justify-content-end align-items-end',
                              ]"
                              v-if="item.param.buy == 1"
                              :style="{
                                width:
                                  item.param.price == 0 &&
                                  (item.param.list_style == 1 ||
                                    item.param.list_style == 2)
                                    ? '100%'
                                    : 'auto',
                              }"
                            >
                              <template
                                v-if="
                                  (item.param.style < 2 ||
                                    item.param.list_style == 1) &&
                                  item.param.list_style < 3
                                "
                              >
                                <div
                                  :class="
                                    'text-more buy-btn-' + item.param.buy_list
                                  "
                                  style="width: auto"
                                  v-if="item.param.buy_list >= 2"
                                >
                                  {{
                                    item.param.buy_content
                                      ? item.param.buy_content
                                      : item.param.buy_default
                                  }}
                                </div>
                                <div
                                  v-else
                                  :class="'buy-btn-' + item.param.buy_list"
                                  :style="{
                                    backgroundImage:
                                      'url(' + item.param.buy_content + ')',
                                  }"
                                ></div>
                              </template>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
            <div :style="{ height: item.param.interval / 2 + 'px' }"></div>
          </div>
        </template>
        <div class="plugin_handle_left" v-if="stencilIndex == index">
          <div class="handle" @click.stop="delStencil(index)">
            <i class="el-icon-close"></i>
          </div>
          <div class="handle" @click.stop="copyStencil(index)">
            <i class="el-icon-copy-document"></i>
          </div>
        </div>
        <div class="plugin_handle_right" v-if="stencilIndex == index">
          <div class="handle" v-if="index > 0" @click.stop="upArrow(index)">
            <i class="el-icon-arrow-up"></i>
          </div>
          <div
            class="handle"
            v-if="index < tem_deta.length - 1"
            @click.stop="downArrow(index)"
          >
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
      </div>
    </div>

    <div
      class="stencil_foot"
      :style="{ background: stencil.bottom_background_color }"
    >
      <div class="foot_conter">
        <div
          v-for="(item, index) in footer_list"
          :key="index"
          class="foot_item"
        >
          <img :src="item.icon" alt="" />
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper } from "swiper";
export default {
  data() {
    return {
      stencil: this.params,
      tem_deta: this.temp_list,
      stencilIndex: -1,
      occupiedImg: require("../../../../assets/diy/default.png"),
      defaultList: {
        goods: {
          list: [
            {
              cat_id: 0,
              cat_name: "商品分类",
              name: "分类副标题",
              goods_list: [
                {
                  id: 0,
                  cover_pic: require("../../../../assets/diy/default.png"),
                  name: "这是商品标题",
                  title: "副标题",
                  price: "999.00",
                  original_price: "1000.00",
                  is_negotiable: 0,
                  integral_content: "999积分",
                },
                {
                  id: 0,
                  cover_pic: require("../../../../assets/diy/default.png"),
                  name: "这是商品标题",
                  price: "999.00",
                  original_price: "1000.00",
                  is_negotiable: 0,
                  integral_content: "999积分",
                },
                {
                  id: 0,
                  cover_pic: require("../../../../assets/diy/default.png"),
                  name: "这是商品标题",
                  price: "999.00",
                  original_price: "1000.00",
                  is_negotiable: 0,
                  integral_content: "999积分",
                },
                {
                  id: 0,
                  cover_pic: require("../../../../assets/diy/default.png"),
                  name: "这是商品标题",
                  price: "999.00",
                  original_price: "1000.00",
                  is_negotiable: 0,
                  integral_content: "999积分",
                },
              ],
              goods_style: 0,
              goods_num: 0,
            },
          ],
        },
      },
      footer_list: [
        {
          icon: "http://dev-jf-ttshop.oeob.net/static/app/images/tab-icon/index.svg",
          name: "首页",
        },
        {
          icon: "http://dev-jf-ttshop.oeob.net/static/app/images/tab-icon/cat.svg",
          name: "分类",
        },
        {
          icon: "http://dev-jf-ttshop.oeob.net/static/app/images/tab-icon/cart.svg",
          name: "购物车",
        },
        {
          icon: "http://dev-jf-ttshop.oeob.net/static/app/images/tab-icon/my.svg",
          name: "我的",
        },
      ],
    };
  },
  props: {
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    temp_list: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  computed: {
    templatePublic() {
      // this.temIndex = this.$parent.temp_list;
      let arr = this.temp_list;
      return arr;
    },
  },

  watch: {
    templatePublic: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.$set(this, "tem_deta", newVal);
          this.$forceUpdate();
        }
        this.resswiper();
      },
      deep: true,
    },
    params: {
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal,'newVal')
        if (newVal.is_open == 1) {
          let color = newVal.background_color;
          this.stencil.backgroundColor = newVal.background_color;
          this.$set(this.stencil, "backgroundColor", color);
          this.$forceUpdate();
        }
        if (!newVal.is_open && newVal.background_color) {
          let arr = newVal.background_color.split(",");
          let color =
            "linear-gradient(270deg," + arr[0] + " 0%," + arr[1] + " 100%)";
          this.stencil.backgroundColor =
            "linear-gradient(270deg," + arr[0] + " 0%," + arr[1] + " 100%)";
          this.$set(this.stencil, "backgroundColor", color);
          this.$forceUpdate();
        }
        this.$set(this, "stencil", newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  mounted() {
    this.resswiper();
  },
  methods: {
    resswiper() {
      this.$nextTick(() => {
        // //console.log(4545)
        var mySwiper = new Swiper(".banner_swiper", {
          autoplay: true,
          loop: true,
          speed: 600,
          centeredSlides: true,
          direction: "horizontal",
          paginationClickable: true,
          preventClicks: false,
        });
        // mySwiper.detachEvents();
        var separateswiper = new Swiper(".isolate_banner", {
          autoplay: false,
          speed: 600,
          roundLengths: true,
          initialSlide: 1.9,
          spaceBetween: 20,
          centeredSlides: true,
          slidesPerView: "auto",
        });
      });
    },
    stencilClick(index) {
      this.stencilIndex = index;
      this.$emit("change", index);
    },
    pubilcCom() {},
    delStencil(index) {
      this.tem_deta.splice(index, 1);
    },
    copyStencil(index) {
      let obj = JSON.parse(JSON.stringify(this.tem_deta[index]));
      obj.id = this.$nanoid();
      this.tem_deta.push(obj);
    },
    upArrow(index) {
      this.$set(this.tem_deta[index], "index", index - 1);
      this.$set(this.tem_deta[index - 1], "index", index + 1);
      this.tem_deta.sort((a, b) => {
        return a.index - b.index;
      });
      this.$forceUpdate();
    },
    downArrow(index) {
      this.$set(this.tem_deta[index], "index", index + 1);
      this.$set(this.tem_deta[index + 1], "index", index);
      this.tem_deta.sort((a, b) => {
        return a.index - b.index;
      });
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
.sten_wrap {
  // background: #fff;
  height: 100%;
  width: 100%;
  position: relative;
}
.block {
  .block_head {
    width: 100%;
    height: 44px;
  }
  .block_header {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .block_logo {
      width: auto;
      height: 40px;
      margin-left: 16px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .block_capsule {
      width: 87.5px;
      height: 32px;
      margin-right: 6px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .block_search {
    width: 351px;
    height: 35px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 36px;
    margin: 0 auto;
    margin-top: 9px;
    margin-bottom: 12px;
    /deep/.el-input {
      width: 100%;
      height: 35px;
      line-height: 35px;
      border: none;
      border-radius: 36px;
      .el-input__inner {
        height: 35px;
        line-height: 35px;
        border-radius: 36px;
        position: absolute;
      }
    }
  }
}
.plugin {
  position: relative;
  .banner_wrap {
    position: relative;
    width: 100%;
    .banner_swiper {
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
    }
    .fillImage {
      object-fit: cover;
    }
    .isolate_banner {
      overflow: hidden;
    }
    .isolate_banner ul li {
      width: 295px;
    }
    //     .isolate_banner .swiper-slide{
    //    width: 295px;
    //    margin: 0 calc(50% - 400px);
    // }
    // .isolate_banner .swiper-slide-prev{
    //    right: calc(295px - 100vw);
    // }
    // .isolate_banner .swiper-slide-next{
    //    left: calc(295px - 100vw);
    // }
  }
  .plugin_handle_left {
    position: absolute;
    left: -28px;
    right: unset;
    top: 0;
    .handle {
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #5cb3fd;
      margin-bottom: 14px;
      cursor: pointer;
      i {
        font-size: 24px;
        color: #fff;
      }
    }
  }
  .plugin_handle_right {
    position: absolute;
    right: -28px;
    top: 0;
    .handle {
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #5cb3fd;
      margin-bottom: 14px;
      cursor: pointer;
      i {
        font-size: 24px;
        color: #fff;
      }
    }
  }
  .nav_wrap {
    position: relative;
    width: 100%;
    .nav-default {
      width: 100%;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .nav_container {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      min-height: 100px;
      .nav_sans {
        flex-shrink: 0;
        width: auto;
        max-width: 100%;
        height: 100%;
        .nav_block {
          padding: 12px 0;
          text-align: center;
          flex-shrink: 0;
          .nav_placeholder {
            width: 42px;
            height: 42px;
          }
          img {
            width: 42px;
            height: 42px;
            margin-bottom: 4px;
            border-radius: 50%;
          }
          p {
            max-width: 100%;
            font-size: 14px;
          }
        }
        .nav-item-3 {
          width: 123px;
        }
        .nav-item-4 {
          width: 92px;
        }
        .nav-item-5 {
          width: 73px;
        }
      }
    }
    .nav_container.active {
      overflow-x: auto;
      min-height: 100px;
    }
  }
  .marke_wrap {
    margin: 10px auto;
    background: #fff;
    border-radius: 7px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    cursor: pointer;
    min-height: 147px;
    .marke_item {
      width: 50%;
      box-sizing: border-box;
      padding: 12px 10px;
      border-bottom: 1px solid #f8f8f8;
      height: 100%;
      &:nth-child(2n + 1) {
        border-right: 1px solid #f8f8f8;
      }
      .marke_headline {
        display: flex;
        align-items: center;
        .marke_title {
          font-size: 16px;
          color: #333333;
          font-weight: bold;
        }
        .marke_label {
          height: 16px;
          font-size: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 4px;
          margin-left: 4px;
          .marke_icon {
            width: 14px;
            height: 14px;
            margin-left: 3px;
          }
        }
        .marke_label-1 {
          color: #fff;
          background: #ff0e25;
          border-radius: 16px;
          padding: 0 5px;
        }
        .marke_label-0 {
          color: #ff0e25;
          border: 1px solid #ff0e25;
          border-radius: 2px;
        }
      }
      .subtitle {
        font-size: 12px;
        color: #cc3f4d;
        margin-top: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .marke_Img {
        display: flex;
        margin-top: 12px;
        img {
          width: 60px;
          height: 60px;
          background: #cccccc;
          margin-left: 16px;
        }
        img:first-child {
          margin-left: 0;
        }
      }
    }
  }
  .goods_wrap {
    width: 100%;
    .good_wrap {
      width: 100%;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      overflow-x: hidden;
      .goods_default {
        padding: 0 5px;
        .goods_cat {
          width: 100px;
          background-color: #eeeeee;
          border-right: 1px solid #e2e2e2;
          min-height: auto;
          .goods-cat-one {
            padding: 13px 0;
            flex-shrink: 0;
            text-align: center;
            margin: 0 8px;
            margin: 0;
            border-bottom: 1px #e2e2e2 solid;
            &:first-child {
              background-color: #fff;
            }
          }
        }
        .good_container {
          width: 271px;
          background-color: #eee;
          .cat_posit_right {
            .cat_posit_title {
              padding: 5px 10px;
              .text-more {
                width: 100px;
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-all;
                display: block;
              }
            }
            .goods-cat-goods {
              // width: 100%;
              padding: 10px;
              background-color: #fff;
              .goods-img-1 {
                width: 76px;
                .goods-img {
                  width: 100%;
                  background-position: center;
                  background-repeat: no-repeat;
                  position: relative;
                  background-size: cover;
                  height: 0;
                  padding-top: 100%;
                  img {
                    width: 32px;
                    height: 32px;
                    position: absolute;
                    left: 0;
                    top: 0;
                  }
                }
                .fill-1 {
                  background-size: cover;
                }
              }
              .goods_left_wrap {
                margin-left: 6px;
                width: 169px;
                .text-more {
                  max-width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  word-break: break-all;
                  display: block;
                }
                .goods_left_price {
                  margin-top: 4px;
                  color: #ff4544;
                }
                .goods-img {
                  width: 100%;
                  background-position: center;
                  background-repeat: no-repeat;
                  position: relative;
                }
                .fill-1 {
                  background-size: cover;
                }
                .fill-0 {
                  background-size: contain;
                }
                .goods-img[data-per="0"] {
                  height: 0;
                  padding-top: 100%;
                }
                .buy-btn-0,
                .buy-btn-1 {
                  width: 25px;
                  height: 25px;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;
                }
                .text-more {
                  max-width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  word-break: break-all;
                  display: block;
                }
                .buy-btn-2 {
                  padding: 2px 15px;
                  border-radius: 25px;
                  background-color: #ff4544;
                  color: #fff;
                  max-width: 100px;
                }
                .buy-btn-3 {
                  padding: 2px 15px;
                  border: 1px solid #ff4544;
                  border-radius: 2px;
                  background-color: #fff;
                  color: #ff4544;
                  max-width: 100px;
                }
                .buy-btn-4 {
                  padding: 2px 15px;
                  border: 1px solid #ff4544;
                  border-radius: 25px;
                  background-color: #fff;
                  color: #ff4544;
                  max-width: 100px;
                }
                .buy-btn-5 {
                  padding: 2px 15px;
                  border-radius: 2px;
                  background-color: #ff4544;
                  color: #fff;
                  max-width: 100px;
                }
              }
            }
          }
        }
      }
      .shop_cat {
        min-height: auto;
        overflow-x: hidden;
        border-bottom: 1px solid #eee;
        padding: 0 12px;
        .mock_cat_list {
          display: flex;
          align-items: center;
        }
        .mock_cat_one {
          padding: 13px 0;
          flex-shrink: 0;
          text-align: center;
          margin: 0 8px;
          .goods-font {
            font-weight: 800;
          }
          .goods-cat-one {
            padding: 13px 0;
            flex-shrink: 0;
            text-align: center;
            margin: 0 8px;
          }
          .goods-cat-one > div {
            padding: 2px 6px;
          }
          .goods-cat-one-0.active {
            border-bottom: 2px solid #ff4544;
            color: #ff4544;
          }
          .goods-cat-one-1.active div {
            color: #fff;
            background-color: #ff4544;
            border-radius: 20px;
          }
        }
      }
      .shop_wrap {
        width: calc(100%);
        min-height: 100px;
        overflow-x: auto;
        .shop_one {
          width: 100%;
          flex-shrink: 0;
          margin: 10px 0;
          background-color: #fff;
          border-radius: 10px;
          overflow: hidden;
          .shop_good {
            .goods-img-0,
            .goods-img-2,
            .goods-img-3,
            .goods-img-4 {
              width: 100%;
            }
            .goods_images {
              width: 100%;
              background-position: center;
              background-repeat: no-repeat;
              position: relative;
            }
            .fill-0 {
              background-size: contain;
            }
            .fill-1 {
              background-size: cover;
            }
            .per-0 {
              height: 0;
              padding-top: 100%;
            }
            .per-1 {
              height: 0;
              padding-top: 66.67%;
            }
            .per-2 {
              height: 0;
              padding-top: 49%;
            }
            .verticleGood_img {
              width: 100%;
              background-position: center;
              background-repeat: no-repeat;
              position: relative;
              img {
                width: 32px;
                height: 32px;
                position: absolute;
                left: 0;
                top: 0;
              }
            }
          }
          .goods-content {
            padding-left: 12px;
            padding-right: 12px;
            flex-shrink: 0;
            flex-grow: 1;
            padding-bottom: 10px;
            width: calc(100% - 24px);
            .goods_name {
              width: 100%;
            }
            .text-more {
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              word-break: break-all;
              display: block;
            }
            .text-more-2 {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              word-break: break-all;
            }
            .goods-price {
              color: #ff4544;
              font-size: 16px;
              padding-top: 12px;
              flex-shrink: 1;
              flex-grow: 1;
              .flex-grow-1 {
                flex-shrink: 1;
                flex-grow: 1;
              }
            }
            .buy-btn {
              .buy-btn-0,
              .buy-btn-1 {
                width: 25px;
                height: 25px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
              }
              .text-more {
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-all;
                display: block;
              }
              .buy-btn-2 {
                padding: 2px 15px;
                border-radius: 25px;
                background-color: #ff4544;
                color: #fff;
                max-width: 100px;
              }
              .buy-btn-3 {
                padding: 2px 15px;
                border: 1px solid #ff4544;
                border-radius: 2px;
                background-color: #fff;
                color: #ff4544;
                max-width: 100px;
              }
              .buy-btn-4 {
                padding: 2px 15px;
                border: 1px solid #ff4544;
                border-radius: 25px;
                background-color: #fff;
                color: #ff4544;
                max-width: 100px;
              }
              .buy-btn-5 {
                padding: 2px 15px;
                border-radius: 2px;
                background-color: #ff4544;
                color: #fff;
                max-width: 100px;
              }
            }
          }
          .goods-content-1 {
            width: 240px;
          }
          .goods-content-0,
          .goods-content-2,
          .goods-content-3,
          .goods-content-4 {
            // width: 100%;
            padding-top: 12px;
          }
          .shop_good-border_0 {
          }
          .shop_good-border_1 {
            border: 1px solid #eee;
            border-radius: 0 0 5px 5px;
          }
          .shop_good-border_ .shop_good-border_2 {
            text-align: center;
          }
          .shop_good-border_3 {
            border: 1px solid #eee;
            border-radius: 0 0 5px 5px;
            text-align: center;
          }
          .shop-goods-img0,
          .shop-goods-img2,
          .shop-goods-img3,
          .shop-goods-img4 {
            width: 100%;
          }
          .shop-goods-img1 {
            width: 100px;
          }
        }
        .shop_one-0 {
          flex-shrink: 0;
          margin: 10px 0;
          background-color: #fff;
          border-radius: 10px;
          overflow: hidden;
        }
        .shop_one-2 {
          width: 49%;
          margin-right: 5px;
        }
        .shop_one-2:nth-child(2n) {
          margin-right: 0;
        }
        .shop_one-3 {
          width: 32%;
          margin-right: 5px;
        }
        .shop_one-3:nth-child(3n) {
          margin-right: 0;
        }
        .shop_one-4 {
          width: 120px;
          margin-right: 5px;
        }
      }
    }
  }
}
.plugin.active {
  border: 2px #5cb3fd solid;
}
.sten_content {
  margin-bottom: 50px;
}

.intergral_unit {
  font-size: 12px;
}
.goods-cat-one div {
  padding: 2px 6px;
}
.stencil_foot {
  width: 100%;
  background: #fff;
  height: 49px;
  position: absolute;
  bottom: 0;
  left: 0;
  .foot_conter {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .foot_item {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      height: 100%;
      position: relative;
      img {
        width: 24px;
        height: 24px;
      }
      p {
        margin-top: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #666;
      }
    }
  }
}
</style>