<template>
  <div class="marke_wrap">
    <div class="banner_head">广告位</div>
    <div class="make_subheading">
      <div class="subheading_title">显示内容</div>
      <div class="subheading_check d-flex flex-wrap">
        <el-checkbox
          v-model="advertising.param.subtitle_show"
          :true-label="1"
          :false-label="0"
          >显示标题</el-checkbox
        >
      </div>
    </div>
    <div class="banner_list d-flex">
      <div class="banner_single">
        <div
          class="banner-single d-flex"
          v-for="(item, index) in advertising.param.list"
          :key="index"
        >
          <div class="marke_one">
            <div class="marke_Input">
              <div class="marke_input_title">标题</div>
              <el-input v-model="item.title"></el-input>
            </div>
            <template v-if="advertising.param.subtitle_show == 1">
              <div class="marke_Input">
                <div class="marke_input_title">副标题</div>
                <el-input v-model="item.subtitle"></el-input>
              </div>

              <div class="marke_Input">
                <div class="marke_input_title">副标题颜色</div>
                <div class="d-flex align-items-center marke_color">
                  <el-color-picker
                    v-model="item.subtitle_color"
                  ></el-color-picker>
                  <el-input
                    v-model="item.subtitle_color"
                    maxlength="7"
                  ></el-input>
                </div>
              </div>
            </template>
            <div class="marke_label_container">
              <div class="marke_label">
                <el-checkbox
                  v-model="item.label_show"
                  :true-label="1"
                  :false-label="0"
                  >标签</el-checkbox
                >
              </div>
              <div v-if="item.label_show == 1" class="marke_label_wrap">
                <div class="marke_Input">
                  <div class="marke_input_title">标签内容</div>
                  <el-input v-model="item.label_content"></el-input>
                </div>
                <div class="marke_Input">
                  <el-radio-group v-model="item.label_style">
                    <el-radio :label="0">样式一</el-radio>
                    <el-radio :label="1">样式二</el-radio>
                  </el-radio-group>
                </div>
                <div class="marke_Input">
                  <el-checkbox
                    v-model="item.label_img_show"
                    :true-label="1"
                    :false-label="0"
                    >标签图标</el-checkbox
                  >
                </div>
                <template v-if="item.label_img_show == 1">
                  <div class="marke_label_img">
                    <div class="upload_group">
                      <template v-if="item.label_img_url">
                        <img :src="item.label_img_url" alt=""  @click.stop="addNavPhoto(index,-1,'label_img')" />
                      </template>
                      <template v-else>
                        <p  @click.stop="addNavPhoto(index,-1,'label_img')">+添加图片</p>
                        <p>建议尺寸28*28</p>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <div
              class="marke_content d-flex justify-content-center"
              v-for="(value, key) in item.pic_list"
              :key="key"
            >
              <div
                class="nav_left d-flex justify-content-center align-items-center"
              >
                <div class="upload_group">
                  <template v-if="value.pic_url">
                    <img :src="value.pic_url" alt=""  @click.stop="addNavPhoto(index,key,'pic_list')" />
                  </template>
                  <template v-else>
                    <p @click.stop="addNavPhoto(index,key,'pic_list')">+添加图片</p>
                    <p>建议尺寸88*88</p>
                  </template>
                </div>
              </div>
              <div class="nav_right">
                <div class="d-flex align-items-center nav_headline">
                  <div class="nav_title">链接页面</div>
                  <el-input
                    :readonly="true"
                    @click.native="markeLink(index,key,'link')"
                    v-model="value.page_name"
                  ></el-input>
                </div>
              </div>
            </div>
          </div>
          <div class="banner-handle">
            <div class="handle" @click.stop="delbanner(index)">
              <i class="el-icon-close"></i>
            </div>
            <div
              class="handle"
              v-if="index < advertising.param.list.length - 1"
              @click.stop="downBanner(index)"
            >
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="handle" v-if="index > 0" @click.stop="upBanner(index)">
              <i class="el-icon-arrow-up"></i>
            </div>
          </div>
        </div>
        <div
          class="banner_add d-flex justify-content-center align-items-center"
          @click.stop="addMarke"
        >
          +添加广告位
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      advertising: this.params,
      navIndex:-1,
      advertiType:'',
      secondIndex:-1,
    };
  },
  props: {
    params: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    addMarke() {
      let obj = {
        title: "",
        subtitle: "",
        subtitle_color: "",
        label_show: 0,
        label_content: "",
        label_style: 0,
        label_img_show: 0,
        label_img_url: "",
        pic_list:[
            {
                pic_url:'',
                page_name:'',
                url:'',  
            },
            {
                pic_url:'',
                page_name:'',
                url:'',  
            },
        ],
      };
      this.advertising.param.list.push(obj);
    },

    delbanner(index) {
      this.advertising.param.list.splice(index,1);
      this.pubilcCom();
    },
    downBanner(index) {
      var options = JSON.parse(
        JSON.stringify(this.advertising.param.list[index + 1])
      );
      this.$set(
        this.advertising.param.list,
        index + 1,
        this.advertising.param.list[index]
      );
      this.$set(this.advertising.param.list, index, options);
      this.pubilcCom();
    },
    // 上升
    upBanner(index) {
      var options = JSON.parse(
        JSON.stringify(this.advertising.param.list[index - 1])
      );
      this.$set(
        this.advertising.param.list,
        index - 1,
        this.advertising.param.list[index]
      );
      this.$set(this.advertising.param.list, index, options);
      this.pubilcCom();
    },
    // 跟父级传值
    pubilcCom() {
      let obj = {
        value: this.advertising,
        type: "all",
      };
      this.$emit("change", obj);
    },
    addNavPhoto(index,key,type) {
      this.navIndex = index;
      this.advertiType=type;
      this.secondIndex=key;
      let obj = {
        value: this.advertising,
        type: "addphoto",
      };
      this.$emit("change", obj);
    },
    markeLink(index,key,type){
      this.navIndex = index;
      this.advertiType=type;
      this.secondIndex=key;
      let obj = {
        value: this.advertising,
        type: "addlink",
      };
      this.$emit("change", obj);
    }
  },
};
</script>
<style lang="scss" scoped>
.marke_wrap {
  width: 100%;
  background: #fff;
  .banner_head {
    padding: 7px;
    display: flex;
    padding-left: 15px;
    font-size: 16px;
    font-weight: 600;
    padding-top: 15px;
  }
  .make_subheading {
    border-bottom: 1px #eee solid;
    padding: 7px;
    .subheading_title {
      font-size: 14px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .subheading_check {
      padding:10px 0;
      padding-left: 15px;
    }
  }
  .banner_list {
    padding: 7px;
    .banner_single {
      width: 97%;
      .banner-single {
        margin-bottom: 7px;
        position: relative;
        .marke_one {
          width: 100%;
          padding: 10px;
          font-size: 9px;
          background-color: #fff;
          border: 1px solid #e2e2e2;
          .marke_Input {
            margin-bottom: 14px;
            display: flex;
            align-items: center;
            .marke_input_title {
              min-width: 60px;
            }
            /deep/.el-input {
              width: 100%;
              margin-left: 15px;
            }
            .marke_color {
              margin-left: 15px;
              /deep/.el-input {
                margin-left: 0;
              }
            }
          }
          .marke_label_container {
            border-bottom: 1px #eee solid;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 14px;
            margin-bottom: 14px;
          }
          .marke_label_wrap {
            margin-left: 14px;
          }
          .marke_label {
            display: flex;
            align-items: center;
          }
          .marke_label_img {
            width: 60px;
            height: 60px;
            border: 1px dashed rgb(238, 238, 238);
            margin: 2px 0px 0px 1rem;
            font-size: 10px;
            padding: 5px 0px;
            text-align: center;
            .upload_group {
              text-align: center;
            }
            p:first-child {
              color: rgb(92, 179, 253);
              cursor: pointer;
              border: 0;
              display: block;
              margin: 0;
            }
            p {
              color: rgb(53, 53, 53);
              font-size: 12px;
            }
            img {
              width: 60px;
              height: 60px;
              text-align: center;
              background-color: #f7f7f7;
            }
          }
          .marke_content {
            margin-bottom: 14px;
            .nav_left {
              width: 80px;
              height: 80px;
              margin-right: 20px;
              background: #f7f7f7;
              .upload_group {
                text-align: center;
              }
              p:first-child {
                color: rgb(92, 179, 253);
                cursor: pointer;
                border: 0;
                display: block;
                margin: 0;
              }
              p {
                color: rgb(53, 53, 53);
                font-size: 12px;
              }
              img {
                width: 80px;
                height: 80px;
                text-align: center;
                background-color: #f7f7f7;
              }
            }
            .nav_right {
              flex: 1;
              .nav_headline {
                .nav_title {
                  text-align: right;
                  // padding: 0 15px;
                  min-width: 60px;
                }
                .el-input {
                  width: 100%;
                  margin-left: 14px;
                }
                .banner_link {
                  /deep/.el-input {
                    .el-input__inner {
                      border-top-right-radius: 0;
                      border-bottom-right-radius: 0;
                    }
                  }
                  /deep/.el-button {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                  }
                }
              }
              .nav_headline:nth-child(2) {
                margin-top: 15px;
              }
            }
          }
        }
        .banner-handle {
          position: absolute;
          right: 0;
          top: 0;
          .handle {
            width: 28px;
            height: 28px;
            margin-bottom: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #5cb3fd;
            i {
              font-size: 24px;
              color: #fff;
            }
          }
        }
      }
    }
    .banner_add {
      width: 100%;
      padding: 5px;
      border: 1px dashed #5cb3fd;
      color: #5cb3fd;
      cursor: pointer;
    }
  }
}
</style>