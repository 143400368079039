<template>
  <div class="banner_wrap">
    <div class="banner_head">轮播图</div>
    <div class="d-flex banner_style">
      <div class="d-flex align-items-center banner-style_title">样式</div>
      <div
        v-for="(item, index) in styleList"
        :key="index"
        :class="['banner-block', banner.param.style == item.id ? 'active' : '']"
        @click.stop="styleCilck(item.id)"
      >
        <template v-if="item.id == 1">
          <div class="style-1"></div>
        </template>
        <template v-else-if="item.id == 2">
          <div class="style-2 d-flex align-items-center justify-content-center">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </template>
        <div class="text">{{ item.name }}</div>
      </div>
    </div>
    <div class="d-flex banner-fill">
      <div class="d-flex align-items-center banner-style_title">填充方式</div>
      <div class="d-flex align-items-center banner-fillter">
        <el-radio-group v-model="banner.param.fill" @change="fillChange">
          <el-radio :label="0">留白</el-radio>
          <el-radio :label="1">填充</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="d-flex banner-height">
      <div class="banner-style_title d-flex align-items-center">轮播图高度</div>
      <div class="banner-height-input">
        <el-input v-model="banner.param.height" type="number">
          <template slot="append">px</template>
        </el-input>
      </div>
    </div>
    <div class="d-flex banner-height">
      <div class="banner-style_title d-flex align-items-center">上下间距</div>
      <div class="banner-height-input">
        <el-input v-model="banner.param.interval" type="number">
          <template slot="append">px</template>
        </el-input>
      </div>
    </div>
    <div class="d-flex banner-width">宽度固定702px</div>
    <div class="banner_list d-flex">
      <div class="banner_single">
        <div
          class="banner-single "
          v-for="(item, index) in banner.param.list"
          :key="index"
        >
          <div class="banner-one d-flex justify-content-center">
            <div
              class="banner_img d-flex justify-content-center align-items-center"
            >
              <div class="upload_group">
                <template v-if="item.pic_url">
                  <img :src="item.pic_url" alt=""  @click.stop="addPhoto(index)" />
                </template>
                <template v-else>
                  <p @click.stop="addPhoto(index)">+添加图片</p>
                  <p>推荐尺寸750*360</p>
                </template>
              </div>
            </div>
            <div class="banner_title d-flex align-items-center">
              <div class="banner_title_lable">标题</div>
              <el-input v-model="item.title"></el-input>
            </div>
            <div class="banner_title d-flex align-items-center">
              <div class="banner_title_lable">链接</div>
              <div class="d-flex align-items-center banner_link">
                <el-input v-model="item.page_name" disabled> </el-input>
                <el-button @click.stop="bannerLink(index)">选择链接</el-button>
              </div>
            </div>
          </div>
          <div class="banner-handle">
            <div class="handle" @click.stop="delbanner(index)">
              <i class="el-icon-close"></i>
            </div>
            <div class="handle" v-if="index<banner.param.list.length-1" @click.stop="downBanner(index)">
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="handle" v-if="index > 0" @click.stop="upBanner(index)">
              <i class="el-icon-arrow-up"></i>
            </div>
          </div>
        </div>
        <div
          class="banner_add d-flex justify-content-center"
          @click="addBanner"
        >
          +添加轮播图
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      banner: this.params,
      styleList: [
        {
          name: "样式一",
          id: 1,
        },
        {
          name: "样式二",
          id: 2,
        },
      ],
      photIndex:-1,
    };
  },
  props: {
    params: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    styleCilck(id) {
      this.banner.param.style = id;
      this.pubilcCom()
    },
    fillChange(e){
      this.pubilcCom()
    },
    // 添加轮播图
    addBanner() {
      let obj = {
        pic_url: "",
        title: "",
        page_url: "",
        page_name:'',
      };
      this.banner.param.list.push(obj);
      this.pubilcCom()
    },
    delbanner(index){
      this.banner.param.list.splice(index,1);
      this.pubilcCom()
    },
    downBanner(index){
      var options = JSON.parse(
        JSON.stringify(this.banner.param.list[index + 1])
      );
      this.$set(
        this.banner.param.list,
        index + 1,
        this.banner.param.list[index]
      );
      this.$set(this.banner.param.list, index, options);
      this.pubilcCom()
    },
    // 下降
    upBanner(index){
      var options = JSON.parse(
        JSON.stringify(this.banner.param.list[index - 1])
      );
      this.$set(
        this.banner.param.list,
        index - 1,
        this.banner.param.list[index]
      );
      this.$set(this.banner.param.list, index, options);
      this.pubilcCom()
    },
    // 跟父级传值
    pubilcCom(){
      let obj={
        value:this.banner,
        type:'all',
      }
      this.$emit('change',obj)
    },
    addPhoto(index){
      this.photIndex=index;
      let obj={
        value:this.banner,
        type:'addphoto',
      }
      this.$emit('change',obj)
    },
    bannerLink(index){
      this.photIndex=index;
      let obj={
        value:this.banner,
        type:'addlink',
      }
      this.$emit('change',obj)
    },
  },
};
</script>
<style lang="scss" scoped>
.banner_wrap {
  min-width: 385px;
  width: 100%;
  background: #fff;
  .banner-style_title {
    font-size: 14px;
    max-width: 25%;
    flex: 0 0 25%;
    padding: 0 15px;
  }
  .banner_head {
    padding: 7px;
    display: flex;
    padding-left: 15px;
    font-size: 16px;
    font-weight: 600;
    padding-top: 15px;
  }
  .banner_style {
    padding: 7px;
    .banner-style_title {
      max-width: 25%;
      flex: 0 0 25%;
      padding: 0 15px;
    }
    .banner-block {
      padding: 5px;
      border: solid 1px #eee;
      cursor: pointer;
      .style-1 {
        width: 100px;
        height: 50px;
        background-color: #e6f4ff;
      }
      .text {
        text-align: center;
        padding: 5px 0;
      }
      .style-2 {
        width: 100px;
        height: 50px;
        padding: 5px 0;
        div:first-child {
          width: 10px;
          border-radius: 0 4px 4px 0;
          margin-right: 2px;
        }
        div {
          width: 80px;
          height: 100%;
          background-color: #e6f4ff;
          border-radius: 4px;
        }
        div:last-child {
          width: 10px;
          border-radius: 4px 0 0 4px;
          margin-left: 2px;
        }
      }
    }
    .banner-block:last-child {
      margin-left: 21px;
    }
    .banner-block.active {
      border: solid 1px #5cb3fd;
    }
  }
  .banner-fill {
    padding: 7px;
    .banner-style_title {
      max-width: 25%;
      flex: 0 0 25%;
      padding: 0 15px;
    }
  }
  .banner-height {
    border-bottom: solid 1px #eee;
    padding: 7px;
    .banner-height-input {
      width: 50%;
      /deep/.el-input {
        width: 100%;
        height: 36px;
        line-height: 36px;
        .el-input__inner {
          height: 36px;
          line-height: 36px;
        }
        .el-input-group__append {
          height: 34px;
          line-height: 34px;
        }
      }
    }
  }
  .banner-width {
    border-bottom: solid 1px #eee;
    padding: 7px;
    font-size: 14px;
    padding-left: 22px;
  }
  .banner_list {
    padding: 7px;
    .banner_single {
      width: 97%;
      .banner-single {
        margin-bottom: 7px;
        position: relative;
        .banner-one {
          width: 100%;
          background-color: #fff;
          padding: 10px;
          border: 1px solid #e2e2e2;
          flex-direction: column;
          align-items: center;
          .banner_img {
            width: 300px;
            // width: 100%;
            height: 144px;
            background-color: #f7f7f7;
            text-align: center;
            .upload_group {
              width: 100%;
              height: 100%;
              p:first-child {
                color: rgb(92, 179, 253);
                cursor: pointer;
                border: 0;
                display: block;
                margin: 0;
                height: 50%;
                line-height: 8;
              }
              p {
                color: rgb(53, 53, 53);
                font-size: 14px;
              }
              img {
                max-width: 100%;
                max-height: 100%;
                margin: auto auto;
              }
            }
          }
          .banner_title {
            margin-top: 21px;
            margin-bottom: 14px;
            width: 100%;
            .banner_title_lable {
              width: 180px;
              -webkit-flex: 0 0 180px;
              -ms-flex: 0 0 180px;
              flex: 0 0 180px;
              text-align: right;
            }
            .el-input {
              width: 100%;
              margin-left: 14px;
            }
            .banner_link {
              flex: 1;
              /deep/.el-input {
                .el-input__inner {
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                }
              }
              /deep/.el-button {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
            }
            @media (min-width: 576px) {
              .banner_title_lable {
                -webkit-box-flex: 0;
                -webkit-flex: 0 0 25%;
                -ms-flex: 0 0 25%;
                flex: 0 0 25%;
                max-width: 15%;
              }
            }
            @media (min-width: 1200px) {
              .banner_title_lable {
                padding-left: 15px;
                padding-right: 15px;
              }
            }
          }
        }
        .banner-handle{
          position:absolute;
          right: 0;
          top: 0;
          .handle{
            width: 28px;
            height: 28px;
            margin-bottom: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #5CB3FD;
            i{
              font-size: 24px;
              color: #fff;
            }
          }
        }
      }
      .banner_add {
        width: 100%;
        padding: 5px;
        border: 1px dashed #5cb3fd;
        color: #5cb3fd;
        cursor: pointer;
      }
    }
  }
}
</style>