<template>
  <div class="nav_wrap">
    <div class="banner_head">导航</div>
    <div class="nav_head d-flex align-items-center">
      <div class="d-flex align-items-center banner-style_title">背景颜色</div>
      <div class="nav_background">
        <el-color-picker
          v-model="navDetail.param.backgroundColor"
        ></el-color-picker>
        <el-input
          v-model="navDetail.param.backgroundColor"
          maxlength="7"
        ></el-input>
      </div>
    </div>
    <div class="nav_head d-flex align-items-center">
      <div class="d-flex align-items-center banner-style_title">一屏显示</div>
      <div class="nav_background">
        <el-select v-model="navDetail.param.count" @change="lineHeight">
          <el-option :label="3" :value="3">3</el-option>
          <el-option :label="4" :value="4">4</el-option>
          <el-option :label="5" :value="5">5</el-option>
        </el-select>
      </div>
    </div>
    <div class="nav_head d-flex align-items-center">
      <div class="d-flex align-items-center banner-style_title">显示行数</div>
      <div class="nav_background">
        <el-input v-model="navDetail.param.col" type="number" @change="lineHeight" :min="1">
          <template slot="append">行</template>
        </el-input>
      </div>
    </div>
    <div class="nav_head d-flex align-items-center">
      <div class="d-flex align-items-center banner-style_title">上下间距</div>
      <div class="nav_background">
        <el-input v-model="navDetail.param.interval" type="number"  :min="1">
          <template slot="append">px</template>
        </el-input>
      </div>
    </div>
    <div class="nav_head d-flex align-items-center">
      <div class="d-flex align-items-center banner-style_title">左右滑动</div>
      <div class="nav_background">
        <el-radio-group v-model="navDetail.param.is_slide">
          <el-radio label="true">是</el-radio>
          <el-radio label="false">否</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="banner_list d-flex">
      <div class="banner_single">
        <div
          class="banner-single d-flex"
          v-for="(item, index) in navDetail.param.list"
          :key="index"
        >
          <div class="nav-one d-flex justify-content-center">
            <div
              class="nav_left d-flex justify-content-center align-items-center"
            >
              <div class="upload_group">
                <template v-if="item.pic_url">
                  <img :src="item.pic_url"  @click.stop="addNavPhoto(index)" alt="" />
                </template>
                <template v-else>
                  <p @click.stop="addNavPhoto(index)">+添加图片</p>
                  <p>建议尺寸88*88</p>
                </template>
              </div>
            </div>
            <div class="nav_right">
              <div class="d-flex align-items-center nav_headline">
                <div class="nav_title">导航名称</div>
                <el-input v-model="item.name" @blur="nameBlur"></el-input>
              </div>
              <div class="d-flex align-items-center nav_headline">
                <div class="nav_title">链接</div>
                <el-input
                  :readonly="true"
                  @click.native="navLink(index)"
                  v-model="item.page_name"
                ></el-input>
              </div>
            </div>
          </div>

          <div class="banner-handle">
            <div class="handle" @click.stop="delbanner(index)">
              <i class="el-icon-close"></i>
            </div>
            <div
              class="handle"
              v-if="index < navDetail.param.list.length - 1"
              @click.stop="downBanner(index)"
            >
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="handle" v-if="index > 0" @click.stop="upBanner(index)">
              <i class="el-icon-arrow-up"></i>
            </div>
          </div>
        </div>
        <div
          class="banner_add d-flex justify-content-center align-items-center"
          @click.stop="addNav"
        >
          +添加导航图标
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navDetail: this.params,
      navIndex: -1,
    };
  },
  props: {
    params: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {},
  methods: {
    addNav() {
      let obj = {
        pic_url: "",
        name: "",
        url: "",
        page_name: "",
      };
      this.navDetail.param.list.push(obj);
      this.lineHeight()
    },
    nameBlur(){
      this.incrementNav()
    },
    // 显示行数
    lineHeight(){
        this.incrementNav()
    },
    addNavDefaul(list, add) {
      let param = this.navDetail.param;
      let count = param.count;
      let col = param.col;
      let ok = false;
      if (list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].length >= count * col) {
            continue;
          }
          ok = true;
          if (list[i].length == 0) {
            list[i] = [add];
          } else {
            list[i].push(add);
          }
        }
      }
      if (!ok) {
        list.push([add]);
      }
      return list;
    },
    incrementNav() {
      let default_list = [],
        list = this.navDetail.param.list;
      for (var i = 0; i < list.length; i++) {
        default_list = this.addNavDefaul(default_list, list[i]);
      }
      this.navDetail.param.default_list = default_list;
    //   this.pubilcCom();
    },
    delbanner(index) {
      this.navDetail.param.list.splice(index,1);
      this.incrementNav()
    },
    downBanner(index) {
      var options = JSON.parse(
        JSON.stringify(this.navDetail.param.list[index + 1])
      );
      this.$set(
        this.navDetail.param.list,
        index + 1,
        this.navDetail.param.list[index]
      );
      this.$set(this.navDetail.param.list, index, options);
      this.incrementNav();
    },
    // 下降
    upBanner(index) {
      var options = JSON.parse(
        JSON.stringify(this.navDetail.param.list[index - 1])
      );
      this.$set(
        this.navDetail.param.list,
        index - 1,
        this.navDetail.param.list[index]
      );
      this.$set(this.navDetail.param.list, index, options);
      this.incrementNav();
    },
    // 跟父级传值
    pubilcCom() {
      let obj = {
        value: this.navDetail,
        type: "all",
      };
      this.$emit("change", obj);
    },
    addNavPhoto(index) {
      this.navIndex = index;
      let obj = {
        value: this.navDetail,
        type: "addphoto",
      };
      this.$emit("change", obj);
    },
    navLink(index){
      this.navIndex=index;
      let obj={
        value:this.navDetail,
        type:'addlink'
      }
      this.$emit("change", obj);
    },
  },
};
</script>
<style lang="scss" scoped>
.nav_wrap {
  width: 100%;
  background: #fff;
  .banner_head {
    padding: 7px;
    display: flex;
    padding-left: 15px;
    font-size: 16px;
    font-weight: 600;
    padding-top: 15px;
  }
  .banner-style_title {
    font-size: 14px;
    max-width: 25%;
    flex: 0 0 25%;
    padding: 0 15px;
  }
  .nav_head {
    padding: 7px;
    .nav_background {
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 100%;
      }
      /deep/.el-select {
        width: 100%;
      }
    }
  }
  .banner_list {
    padding: 7px;
    .banner_single {
      width: 97%;
      .banner-single {
        margin-bottom: 7px;
        position: relative;
        .nav-one {
          width: 100%;
          padding: 10px;
          font-size: 9px;
          background-color: #fff;
          border: 1px solid #e2e2e2;
          .nav_left {
            width: 80px;
            height: 80px;
            margin-right: 20px;
            background: #f7f7f7;
            .upload_group {
              text-align: center;
            }
            p:first-child {
              color: rgb(92, 179, 253);
              cursor: pointer;
              border: 0;
              display: block;
              margin: 0;
            }
            p {
              color: rgb(53, 53, 53);
              font-size: 12px;
            }
            img {
                width: 80px;
                height: 80px;
                text-align: center;
                background-color: #f7f7f7;
            }
          }
          .nav_right {
            flex: 1;
            .nav_headline {
              .nav_title {
                text-align: right;
                // padding: 0 15px;
                min-width: 60px;
              }
              .el-input {
                width: 100%;
                margin-left: 14px;
              }
              .banner_link {
                /deep/.el-input {
                  .el-input__inner {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                  }
                }
                /deep/.el-button {
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                }
              }
            }
            .nav_headline:nth-child(2) {
              margin-top: 15px;
            }
          }
        }
        .banner-handle {
          position: absolute;
          right: 0;
          top: 0;
          .handle {
            width: 28px;
            height: 28px;
            margin-bottom: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #5cb3fd;
            i {
              font-size: 24px;
              color: #fff;
            }
          }
        }
      }

      .banner_add {
        width: 100%;
        padding: 5px;
        border: 1px dashed #5cb3fd;
        color: #5cb3fd;
        cursor: pointer;
      }
    }
  }
}
</style>