<template>
  <div class="container">
    <div class="pannel_head">
      <p>模板编辑</p>
    </div>
    <div class="pannel_body d-flex flex-row">
      <div class="pannel_menu">
        <div class="menu_head">
          <p>模板名称：</p>
          <el-input
            v-model="template_name"
            placeholder="请输入模板名称"
          ></el-input>
        </div>
        <div class="ment_toast">
          <label for="">温馨提示：</label>
          <label for="">最多添加20个组件</label>
        </div>
        <div class="ment_color">
          <div class="menu_title">导航栏颜色：</div>

          <div
            class="d-flex align-items-center flex-wrap color_list"
            v-if="temple_data.is_open == 0"
          >
            <div
              v-for="(item, index) in colorList"
              :key="index"
              :class="['color_single', index == activeColor ? 'active' : '']"
              @click.stop="colorSelect(index)"
            >
              <div :style="{ background: item.background }"></div>
              <p>{{ item.name }}</p>
            </div>
          </div>
          <div class="menu_color d-flex align-items-center">
            <p>是否开启自定义颜色：</p>
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="temple_data.is_open"
              @change="colorSwitch"
            ></el-switch>
          </div>
          <div class="menu_custom" v-if="temple_data.is_open == 1">
            <div class="d-flex align-items-center menu_defined">
              <el-color-picker
                v-model="temple_data.background_color"
                @change="bottomChange"
              ></el-color-picker>
              <el-input
                v-model="temple_data.background_color"
                readonly
              ></el-input>
            </div>
          </div>

          <div class="menu_title">底部导航栏颜色</div>
          <div class="d-flex align-items-center menu_defined">
            <el-color-picker
              v-model="temple_data.bottom_background_color"
              @change="bottomColorChange"
            ></el-color-picker>
            <el-input
              v-model="temple_data.bottom_background_color"
              readonly
            ></el-input>
          </div>
        </div>
        <div class="menu_nav">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item
              v-for="(item, index) in module_list"
              :key="index"
              :title="item.name"
              :name="item.id"
            >
              <div class="d-flex flex-row flex-wrap module_list">
                <div
                  v-for="(i, b) in item.sub"
                  :key="b"
                  class="module_single"
                  @click.stop="activeModule(i.type)"
                >
                  <div>
                    <img :src="i.icon" />
                  </div>
                  <div>{{ i.name }}</div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="pannel_template">
        <div class="pannel_form">
          <div class="pannel_board" ref="imageTofile">
            <stencil
              :params="temple_data"
              :temp_list="temp_list"
              @change="stencilChange"
            ></stencil>
          </div>
        </div>
      </div>
      <div class="pannel_module">
        <div
          v-for="(item, index) in temp_list"
          :key="item.id"
          class="pannel_compon"
        >
          <template v-if="temp_index == index">
            <template v-if="item.type == 'banner'">
              <banner
                ref="bannerSwiper"
                :params="item"
                @change="bannerChange"
              ></banner>
            </template>
            <template v-else-if="item.type == 'nav'">
              <navSten
                :params="item"
                @change="navChange"
                ref="navigation"
              ></navSten>
            </template>
            <template v-else-if="item.type == 'marketing'">
              <marketing
                :params="item"
                @change="advertiChange"
                ref="advertising"
              ></marketing>
            </template>
            <template v-else-if="item.type == 'goods'">
              <shopGoods
                :params="item"
                @change="shopChange"
                ref="shopgoods"
              ></shopGoods>
            </template>
          </template>
        </div>
      </div>
    </div>
    <diy-footer-button>
      <el-button type="primary" @click.stop="preview" :loading="caijiLoading"
        >保存</el-button
      >
    </diy-footer-button>
    <pictureSelect
      ref="pictureSelect"
      :multipled="is_multiple"
      @change="imgbrand($event)"
    ></pictureSelect>
    <shopselector
      ref="shopList"
      @change="goodshoplist($event)"
      :isMobile="true"
      :mobileActive="mobileActive"
      :fitmentClass="fitmentClass"
    ></shopselector>
    <shopClass
      ref="shopClass"
      @change="classification"
      :isMobile="true"
      :mobileActive="mobileActive"
    ></shopClass>
    <linkUrlChoice
      ref="selectLink"
      @change="linkGet"
      :isMobile="true"
      :mobileActive="mobileActive"
    ></linkUrlChoice>
  </div>
</template>
<script>
import stencil from "./components/stencil.vue";
import banner from "./components/banner.vue";
import navSten from "./components/nav.vue";
import marketing from "./components/marketing.vue";
import shopGoods from "./components/shopgood.vue";
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      template_name: "", //模板名称
      activeName: "",
      module_list: [
        {
          name: "商品类",
          id: 1,
          sub: [
            {
              name: "商品",
              type: "goods",
              icon: require("../../../assets/mobile/goods.png"),
            },
          ],
        },
        {
          name: "基础组件",
          id: 2,
          sub: [
            {
              name: "导航图标",
              type: "nav",
              icon: require("../../../assets/mobile/nav.png"),
            },
            {
              name: "轮播图广告",
              type: "banner",
              icon: require("../../../assets/mobile/banner.png"),
            },
            {
              name: "豆腐块",
              type: "marketing",
              icon: require("../../../assets/mobile/marketing.png"),
            },
            // {
            //   name: "魔方",
            //   type: "rubik",
            //   icon: require("../../../assets/mobile/rubik.png"),
            // },
          ],
        },
      ],
      temple_data: {
        is_open: 0,
        bottom_background_color: "#fbfbfb",
        background_color: "#0fd3b0,#04e382",
        logo: "",
      },
      temp_list: [], //组件列表
      temp_index: -1,
      element_list: [
        {
          type: "banner",
          param: {
            style: 1,
            fill: 1,
            interval: 4,
            height: 360,
            list: [],
          },
        },
        {
          type: "nav",
          param: {
            backgroundColor: "#ffffff",
            interval: 4,
            is_slide: "true",
            count: 3,
            col: 1,
            list: [],
            default_list: [],
          },
        },
        {
          type: "marketing",
          param: {
            list: [
              {
                title: "标题",
                subtitle: "副标题",
                subtitle_color: "",
                label_show: 0,
                label_content: "",
                label_style: 0,
                label_img_show: 0,
                label_img_url: "",
                pic_list: [
                  {
                    pic_url: "",
                    page_name: "",
                    url: "",
                  },
                  {
                    pic_url: "",
                    page_name: "",
                    url: "",
                  },
                ],
              },
            ],
            subtitle_show: 1,
          },
        },
        {
          type: "goods",
          param: {
            interval: 4,
            is_cat: 0,
            cat_position: 0,
            cat_style: 0,
            list: [],
            list_style: "0",
            fill: 1,
            per: 0,
            style: 0,
            name: 1,
            price: 1,
            buy: 1,
            buy_list: 0,
            buy_content: location.origin + location.pathname + "static/cat.png",
            buy_default: "购买",
            mark: 0,
            mark_list: 0,
            mark_url: location.origin + location.pathname + "static/rx.png",
            interval: 4,
          },
        },
      ],
      is_multiple: 0,
      stencilType: "",
      mobileActive: this.$route.query.active_id||this.$route.query.activity_id || "0",
      caijiLoading: false,
      cover_img_url: "",
      finish_id: this.$route.query.id,
      finishDeatail: "",
      shoptype: [],
      fitmentClass: [],
      colorList: [
        {
          name: "热情红",
          type: 0,
          color: "#ff3883,#ff2d2d",
          background: "linear-gradient(rgb(255, 56, 131), rgb(255, 45, 45))",
        },
        {
          name: "活力橘",
          type: 1,
          color: "#ff9320,#ff6c24",
          background: "linear-gradient(rgb(255, 147, 32), rgb(255, 108, 36))",
        },
        {
          name: "格调金",
          type: 2,
          color: "#f5cc94,#d8a96c",
          background: "linear-gradient(rgb(245, 204, 148), rgb(216, 169, 108))",
        },
        {
          name: "纯净绿",
          type: 3,
          color: "#0fd3b0,#04e382",
          background: "linear-gradient(rgb(15, 211, 176), rgb(4, 227, 130))",
        },
      ],
      activeColor: 3,
      syndicateData: {},
    };
  },
  components: {
    stencil,
    banner,
    navSten,
    marketing,
    shopGoods,
  },
  created() {
    let that = this;
    this.common.getCorporation(this, function () {
      that.$set(that.temple_data, "logo", that.syndicateData.company.logo);
      that.temple_data.logo=that.syndicateData.company.logo;
      that.$forceUpdate();
    });
    if (this.finish_id) {
      this.getDetail();
    } else {
      this.temple_data.background_color =
        this.colorList[this.activeColor].color;
    }
  },
  methods: {
    colorSelect(index) {
      this.activeColor = index;
      this.temple_data.background_color = this.colorList[index].color;
      this.$set(
        this.temple_data,
        "background_color",
        this.colorList[index].color
      );
    },
    activeModule(type) {
      if (this.temp_list.length > 20 || this.temp_list.length == 20) {
        this.$message({
          type: "warning",
          message: "最多添加20个组件",
          showClose: true,
          offset: 200,
          duration: 1000,
        });
        return false;
      }

      let obj = this.element_list.find((item) => item.type == type);
      let items = JSON.parse(JSON.stringify(obj));
      let data = {
        type: obj.type,
        id: this.$nanoid(),
      };
      let objdata = Object.assign(data, items);
      this.temp_list.push(objdata);
      // //console.log(obj)
      this.activeName = "";
    },
    // 图片选择器
    imgbrand(value) {
      // //console.log(value);
      if (!value) {
        return false;
      }
      if (this.stencilType == "banner") {
        this.temp_list[this.temp_index].param.list[
          this.$refs.bannerSwiper[0].photIndex
        ].pic_url = value[0].file_url;
      } else if (this.stencilType == "nav") {
        this.temp_list[this.temp_index].param.list[
          this.$refs.navigation[0].navIndex
        ].pic_url = value[0].file_url;
        this.$refs.navigation[0].incrementNav()
      } else if (this.stencilType == "marketing") {
        //console.log(this.$refs.advertising[0].advertiType);
        if (this.$refs.advertising[0].advertiType == "pic_list") {
          this.temp_list[this.temp_index].param.list[
            this.$refs.advertising[0].navIndex
          ].pic_list[this.$refs.advertising[0].secondIndex].pic_url =
            value[0].file_url;
        } else if (this.$refs.advertising[0].advertiType == "label_img") {
          this.temp_list[this.temp_index].param.list[
            this.$refs.advertising[0].navIndex
          ].label_img_url = value[0].file_url;
        }
      } else if (this.stencilType == "goods") {
        this.temp_list[this.temp_index].param.mark_url = value[0].file_url;
      }
    },
    // 分类选择器
    classification(value) {
      if (!value) {
        return;
      }
      if (this.stencilType == "goods") {
        value.some(item=>{item.goods_style=0})
        let arr = this.temp_list[this.temp_index].param.list;
        console.log(value)
        arr = arr.concat(value);
        for (let i = 0; i < arr.length; i++) {
          for (let j = i + 1; j < arr.length; j++) {
            if (arr[i].id === arr[j].id) {
              arr.splice(j, 1);
              j--;
            }
          }
        }
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].hasOwnProperty("goods_list")) {
            arr[i].goods_list = [];
          }
        }
        this.temp_list[this.temp_index].param.list = arr;
      }
    },
    // 链接选择器
    linkGet(value) {
      //console.log(value);
      if (!value) {
        return false;
      }
      if (this.stencilType == "nav") {
        this.temp_list[this.temp_index].param.list[
          this.$refs.navigation[0].navIndex
        ].url = value.link;
        this.temp_list[this.temp_index].param.list[
          this.$refs.navigation[0].navIndex
        ].page_name = value.name;
        this.$refs.navigation[0].incrementNav()
      } else if (this.stencilType == "banner") {
        this.temp_list[this.temp_index].param.list[
          this.$refs.bannerSwiper[0].photIndex
        ].page_url = value.link;
        this.temp_list[this.temp_index].param.list[
          this.$refs.bannerSwiper[0].photIndex
        ].page_name = value.name;
      } else if (this.stencilType == "marketing") {
        this.temp_list[this.temp_index].param.list[
          this.$refs.advertising[0].navIndex
        ].pic_list[this.$refs.advertising[0].secondIndex].page_name =
          value.name;
        this.temp_list[this.temp_index].param.list[
          this.$refs.advertising[0].navIndex
        ].pic_list[this.$refs.advertising[0].secondIndex].url = value.link;
      }
    },
    stencilChange(index) {
      this.temp_index = index;
    },
    // 轮播图装修返回的数据
    bannerChange(value) {
      this.stencilType = "banner";
      if (value.type == "addphoto") {
        this.$refs.pictureSelect.photoVisible =
          !this.$refs.pictureSelect.photoVisible;
      } else if (value.type == "addlink") {
        this.$refs.selectLink.linkVisible = !this.$refs.selectLink.linkVisible;
      }
      this.$set(this.temp_list, this.temp_index, value.value);
    },
    // 导航装修返回的数据
    navChange(value) {
      this.stencilType = "nav";
      if (value.type == "addphoto") {
        this.$refs.pictureSelect.photoVisible =
          !this.$refs.pictureSelect.photoVisible;
      } else if (value.type == "addlink") {
        this.$refs.selectLink.linkVisible = !this.$refs.selectLink.linkVisible;
      }
      this.$set(this.temp_list, this.temp_index, value.value);
    },
    advertiChange(value) {
      this.stencilType = "marketing";
      if (value.type == "addphoto") {
        this.$refs.pictureSelect.photoVisible =
          !this.$refs.pictureSelect.photoVisible;
      } else if (value.type == "addlink") {
        this.$refs.selectLink.linkVisible = !this.$refs.selectLink.linkVisible;
      }
      this.$set(this.temp_list, this.temp_index, value.value);
    },
    // 保存
    preview() {
      if (!this.template_name) {
        this.$message({
          type: "warning",
          message: "模板名称不能为空",
          showClose: true,
          offset: 200,
          duration: 1000,
        });
        return false;
      }
      // 手动创建一个 canvas 标签
      const canvas = document.createElement("canvas");
      // 获取父标签，意思是这个标签内的 DOM 元素生成图片
      // imageTofile是给截图范围内的父级元素自定义的ref名称
      let canvasBox = this.$refs.imageTofile;
      // 获取父级的宽高
      const width = parseInt(window.getComputedStyle(canvasBox).width);
      const height = parseInt(window.getComputedStyle(canvasBox).height);
      // 宽高 * 2 并放大 2 倍 是为了防止图片模糊
      canvas.width = width * 2;
      canvas.height = height * 2;
      canvas.style.width = width + "px";
      canvas.style.height = height + "px";
      const context = canvas.getContext("2d");
      context.scale(2, 2);
      const options = {
        backgroundColor: null,
        canvas: canvas,
        useCORS: true,
      };
      html2canvas(canvasBox, options).then((canvas) => {
        // toDataURL 图片格式转成 base64
        let dataURL = canvas.toDataURL("image/png");
        let fileName = this.Generatename();
        let fileImg = this.dataURLtoFile(dataURL, fileName);
        //console.log(fileImg);
        this.uploadImg(fileImg);
      });
      this.caijiLoading = true;
    },
    Generatename() {
      const yy = new Date().getFullYear();
      const MM =
        new Date().getMonth() + 1 < 10
          ? "0" + (new Date().getMonth() + 1)
          : new Date().getMonth() + 1;
      const dd =
        new Date().getDate() < 10
          ? "0" + new Date().getDate()
          : new Date().getDate();
      const HH =
        new Date().getHours() < 10
          ? "0" + new Date().getHours()
          : new Date().getHours();
      const mm =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      const ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      return yy + MM + dd + "-" + HH + mm + ss + ".png";
    },
    dataURLtoFile(dataurl, fileName) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, { type: mime });
    },
    uploadImg(file) {
      let that = this;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("is_ignore", true);
      let config = {
        headers: {
          "Content-Type": "mutipart/form-data",
        },
      };
      this.$post(this.$apis.upImg, formData, config)
        .then((res) => {
          // //console.log(res);
          if (res.code == 200) {
            that.cover_img_url = res.data.url;
            that.conserve();
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch((err) => {
          //console.log(err);
          this.common.message(this, err);
        });
    },
    conserve() {
      let that = this;
      let temp_list = JSON.parse(JSON.stringify(this.temp_list));
      temp_list.some((item) => {
        //console.log(item)
        if (item.type == "goods" && item.param.list.length) {
          item.param.list.forEach((i) => {
            let complex = [];
            i.goods_list.forEach((a) => {
              if (a) {
                complex.push(a.id);
              }
            });
            i.goods_list = complex;
          });
        }
      });
      // //console.log(temp_list);
      // return
      let params = {
        activity_id: this.mobileActive,
        template: temp_list,
        name: this.template_name,
        cover_img_url: this.cover_img_url,
        background_color: this.temple_data.background_color || "#0fd3b0",
        bottom_background_color: this.temple_data.bottom_background_color,
        is_open: this.temple_data.is_open,
        front_color: "",
      };
      console.log(JSON.parse(JSON.stringify(params)) )
      if (this.finish_id) {
        this.redactBoard(params);
        return false;
      }
      this.$post(this.$apis.mobileFinishSave, params)
        .then((res) => {
          that.caijiLoading=false;
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "保存成功",
              showClose: true,
              offset: 200,
              duration: 1000,
            });
            setTimeout(function () {
              // that.$router.replace("/client/mobile/finish");
              // that.$router.go(-1);
            }, 1500);
          } else {
            that.common.message(this, res.message);
          }
        })
        .catch((err) => {
          that.caijiLoading=false;
          that.common.message(this, err.message);
        });
    },
    redactBoard(value) {
      let that = this;
      let data = value;
      data.type = this.temple_data.type;
      data.deleted_at = this.temple_data.deleted_at;
      data.status = this.temple_data.status;
      data.is_index = this.temple_data.is_index;
      data.page_id = this.temple_data.page_id;
      data.id = this.finish_id;
      this.$put(this.$apis.mobileFinishSave + "/" + this.finish_id, data)
        .then((res) => {
          that.caijiLoading=false;
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "编辑成功",
              showClose: true,
              offset: 200,
              duration: 1000,
            });
            setTimeout(function () {
              // that.$router.replace("/client/mobile/finish");
              // that.$router.go(-1);
            }, 1500);
          } else {
            that.common.message(that, res.message);
          }
        })
        .catch((err) => {
          that.caijiLoading=false;
          //console.log(err);
          that.common.message(that, err.message);
        });
    },
    getDetail() {
      let that = this;
      this.$get(this.$apis.mobileFinishDetail + this.finish_id)
        .then((res) => {
          if (res.code == 200) {
            if(res.data.template.length){
              res.data.template=res.data.template.filter((item)=>{
                if(item.type=='goods'){
                return item.param.list.filter(i=>{
                   return i.goods_list.filter(m=>{
                      return i!=null&&i!=''
                    })
                  })
                }
                return item
              })
              that.getTemplateExtend();
            }
            that.temp_list = res.data.template;
            that.template_name = res.data.name;
            that.finishDeatail = res.data;
            that.temple_data = Object.assign({},that.temple_data,res.data);
            // that.temple_data.background_color = res.data.background_color;
            if(res.data.is_open==0){
              this.activeColor=that.colorList.findIndex((item)=>item.color==res.data.background_color)
            }
          } else {
            that.common.message(this, res.message);
          }
        })
        .catch((err) => {
          that.common.message(this, err.message);
        });
    },
    // 获取扩展信息
    getTemplateExtend() {
      let that = this;
      this.$get(
        this.$apis.mobileFinishDetailextend +
          this.finish_id +
          "/get_template_extend"
      )
        .then((res) => {
          let temp_list = this.temp_list;
          temp_list.forEach((item, index) => {
            if (item.type == "goods") {
              for (var i in res.data.goods_list) {
                item.param.list.forEach((j, k) => {
                  j.goods_list.some((a, c) => {
                    if (a == i) {
                      this.$set(
                        temp_list[index].param.list[k].goods_list,
                        c,
                        res.data.goods_list[i]
                      );
                    }
                  });
                });
              }
              for(var a in res.data.category_map){
                item.param.list.forEach((m,n)=>{
                  if(a==m.id){
                    let obj=Object.assign({},m,res.data.category_map[a]);
                    this.$set(
                        temp_list[index].param.list,
                        n,
                        obj
                      );
                  }
                })
              }
            }
          });
          // //console.log(temp_list)
          that.$nextTick(() => {
            that.$set(that, "temp_list", temp_list);
            that.$forceUpdate();
            // //console.log(that.temp_list);
          });
        })
        .catch((err) => {
          //console.log(err);
          that.common.message(that, err.message);
        });
    },
    // 商品组件选择
    shopChange(value) {
      this.fitmentClass=[]
      // console.log(value);
      this.stencilType = "goods";
      if (value.type == "goods") {
        this.fitmentClass = value.catId;
        this.$refs.shopList.shoptype = !this.$refs.shopList.shoptype;
      } else if (value.type == "class") {
        this.$refs.shopClass.classVisible = !this.$refs.shopClass.classVisible;
      } else if (value.type == "mark_url") {
        this.$refs.pictureSelect.photoVisible =
          !this.$refs.pictureSelect.photoVisible;
      }
      this.$set(this.temp_list, this.temp_index, value.value);
    },
    goodshoplist(value) {
      if (!value) {
        return false;
      }
      if (this.stencilType == "goods") {
        let arr = this.temp_list[this.temp_index].param.list[
          this.$refs.shopgoods[0].catIndex
        ]
          ? this.temp_list[this.temp_index].param.list[
              this.$refs.shopgoods[0].catIndex
            ].goods_list
          : [];
        arr = arr.concat(value);
        var newArr = arr.filter(function (item, index) {
          return arr.indexOf(item) === index; // 因为indexOf 只能查找到第一个
        });
        let obj = this.temp_list[this.temp_index].param.list[
          this.$refs.shopgoods[0].catIndex
        ]
          ? this.temp_list[this.temp_index].param.list[
              this.$refs.shopgoods[0].catIndex
            ]
          : { goods_list: [] };
          console.log(newArr)
        this.$set(this.temp_list[this.temp_index].param.list, this.$refs.shopgoods[0].catIndex, obj);
        this.$set(
          this.temp_list[this.temp_index].param.list[
            this.$refs.shopgoods[0].catIndex
          ],
          "goods_list",
          newArr
        );
        this.temp_list[this.temp_index].param.list[
          this.$refs.shopgoods[0].catIndex
        ].goods_list = newArr;
        console.log(this.temp_list[this.temp_index].param.list)
        this.$forceUpdate()
      }
    },
    bottomChange(e) {
      this.temple_data.background_color = e;
      this.$set(this.temple_data, "background_color", e);
    },
    bottomColorChange(e) {
      this.temple_data.bottom_background_color = e;
      this.$set(this.temple_data, "bottom_background_color", e);
    },
    colorSwitch(e) {
      if (e) {
        this.temple_data.background_color = "#0fd3b0";
        this.$set(this.temple_data, "background_color", "#0fd3b0");
      } else {
        this.temple_data.background_color =
          this.colorList[this.activeColor].color;
        this.$set(
          this.temple_data,
          "background_color",
          this.colorList[this.activeColor].color
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  min-width: 100%;
  background: #fff;
  border-radius: 4px;
  .pannel_head {
    padding: 14px 0px;
    margin: 0px 14px;
    box-sizing: border-box;
    border-bottom: 1px solid #eceeef;
  }
  .pannel_body {
    height: 760px;
    .pannel_menu {
      // width: 275px;
      width: 310px;
      padding: 16.8px;
      .menu_head {
        padding-left: 15px;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        p {
          position: relative;
          &::after {
            content: "*";
            display: inline;
            position: absolute;
            left: -10px;
            color: #ff4544;
            font-weight: bolder;
            top: 20%;
            right: -10px;
          }
        }
        /deep/.el-input {
          width: 174px;
          margin-left: 14px;
        }
      }
      .ment_toast {
        font-size: 14px;
        color: #d9534f;
      }
      .ment_color {
        margin: 10px 0;
        .menu_title {
          font-size: 16px;
          font-weight: 600;
          line-height: 46px;
        }
        .color_list {
          .color_single {
            // width: 142px;
            height: 52px;
            line-height: 52px;
            border: 1px solid #ededed;
            -webkit-box-shadow: 4px 4px 6px #f5f5f5;
            box-shadow: 4px 4px 6px #f5f5f5;
            background: #fff;
            border-radius: 4px;
            overflow: hidden;
            padding: 0 11px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
            margin-bottom: 8px;
            cursor: pointer;
            div {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              background: red;
            }
            p {
              margin-left: 5px;
              font-size: 14px;
              color: #333;
              margin-bottom: 0;
            }
          }
          .color_single.active {
            border-color: #00a0e9;
          }
        }
        .menu_color {
          padding: 7px;
          p {
            margin-right: 14px;
            font-size: 14px;
          }
        }
        .menu_custom {
          .menu_defined {
            margin: 14px 0;
          }
          > p {
            text-align: center;
            font-weight: 700;
          }
          /deep/.el-input {
            width: 100%;
          }
        }
      }
      .menu_nav {
        /deep/.el-collapse {
          border: none;
          .el-collapse-item {
            margin-top: 10px;
            .el-collapse-item__header {
              border-bottom: none;
              .el-collapse-item__arrow {
                transform: rotate(-90deg);
              }
              .el-collapse-item__arrow.is-active {
                transform: rotate(90deg);
              }
            }
            .el-collapse-item__wrap {
              border-bottom: none;
            }
          }
        }
        .module_list {
          background-color: rgb(248, 248, 250);
          padding: 3px;
        }
        .module_single {
          width: 82px;
          height: 82px;
          padding: 9px 0;
          box-sizing: border-box;
          text-align: center;
          cursor: pointer;
          margin: 3px;
          background-color: #fff;
          &:hover {
            background-color: #eee;
          }
          img {
            width: 39px;
            height: 39px;
            margin-bottom: 4px;
          }
        }
      }
    }
    .pannel_template {
      width: 50%;
      background-color: #f2f4f7;
      max-height: 100%;
      overflow-y: auto;
      min-width: 462px;
      .pannel_form {
        min-height: 100%;
        display: flex;
        justify-content: center;
        padding: 20px;
        .pannel_board {
          min-height: 100%;
          border: 1px solid rgb(221, 221, 221);
          border-radius: 4px;
          padding: 10px;
          width: 375px;
        }
      }
    }
    .pannel_module {
      min-width: 385px;
      // width: 385px;
      overflow-y: auto;
      max-height: 100%;
      width: 9rem;
      .pannel_compon {
        margin: 0 7px;
        padding-bottom: 20px;
      }
    }
  }
}
</style>
<style>
.el-color-picker__panel
  .el-color-dropdown__btns
  .el-color-dropdown__value
  .el-input {
  width: 100%;
}
.el-color-picker__panel .el-color-dropdown__btns .el-button {
  padding: 0;
}
.el-color-picker__panel .el-color-dropdown__btns .is-plain {
  padding: 7px 15px;
}
</style>